import React from 'react'
import { useEffect, useState } from 'react';
import axios from 'axios';
import dogImg from '../../assets/images/dog.png';
import "../News/News.scss"
import Scrnshot from "../../assets/images/cc_scrnsht.png"

function News() {
    const [newData, setNewData] = useState([])

    useEffect(() => {
        axios.get("https://api.collarswap.io/api/cms/listcms")
            .then((res) => {
                setNewData(res.data.result[0].cmsData)
            })

    }, [])

    return (
        <div className="cnt-load-div">
            <div className="container-fluid p-0">
                <div className="news-page">
                    <div className="container">
                        <div className="row justify-content-between b-b">
                            <div className="col-lg-6 pt-3">
                                <div className="news-heading d-flex justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <img src={dogImg} alt="" />
                                        <p>News & Announcements</p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-4 pt-3">

                            </div>
                        </div>

                        <div className="row my-3">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-12 py-2 b-b">
                                        <div className="main-heading">
                                            <h6>Collar Swap , How it works?...!</h6>
                                            {/* <p>9hrs ago <i className="fa fa-reply pl-3"></i></p> */}
                                        </div>
                                        <div className="my-3 main-bg">
                                            <div align="center">
                                            <img src={Scrnshot} alt="selectpair" height="600px" width="800px" />
                                            </div>

                                            <p dangerouslySetInnerHTML={{ __html: newData }}>
                                            </p>                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default News
