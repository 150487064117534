import React, { Component, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import './App.css';
import './style.scss';

// const ViewHome = React.lazy(() =>
import ViewHome from './views/Home/Home'

// const ViewSwap = React.lazy(() =>
import ViewSwap from './views/Swap/Swap';

// const ViewLiquidity = React.lazy(() =>
import ViewLiquidity from './views/Liquidity/Liquidity';

// const ViewStake = React.lazy(() =>
import ViewStake from './views/Stake/Stake';

// const ViewPagenotfound = React.lazy(() =>
import ViewPagenotfound from './views/Pagenotfound/Pagenotfound';


import ViweNewsPage from './views/News/News';

import ViewFlexStake from "./views/flexstake/Flexstake"

import ViewLockStake from "./views/Lockstake/Lockstake"
import Lockstake from './views/Lockstake/Lockstake';


function App(props) {

  let inactivityTime = function () {
    let time;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function logout() {
      if('caches' in window){
        caches.keys().then((names) => {
                // Delete all the cache files
                names.forEach(name => {
                    caches.delete(name);
                })
            });
      
            // Makes sure the page reloads. Changes are only visible after you refresh.
            window.location.reload(true);
        }
        document.cookie.split(";").forEach((c) => {
          document.cookie = c
            .replace(/^ +/, "")
            .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
    }
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(logout, 180000)
    }
  };
  inactivityTime();





  return (
    <React.Fragment>
      <Suspense fallback={<div className="loading" />}>
        <Router history={props.history}>
          <Switch>
            <Route
              path="/"
              exact
              component={ViewHome}
            />
            <Route
              path="/swap"
              exact
              component={ViewSwap}
            />
            <Route
              path="/liquidity"
              exact
              component={ViewLiquidity}
            />
            <Route
              path="/stake"
              exact
              component={ViewStake}
            />
               <Route
              path="/flexstake"
              exact
              component={ViewFlexStake}
            />
              <Route
              path="/Lockstakev2"
              exact
              component={Lockstake}
            />
            <Route
              path="/lockstakemain"
              exact
              component={ViewPagenotfound}
            />

            <Route
              path="/News"
              exact
              component={ViweNewsPage}
            />
          </Switch>
        </Router>
      </Suspense>
      <ToastContainer />

    </React.Fragment>
  );

}

export default App;
