/*eslint-disable*/
import React, { Fragment, Component, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import ReactDOM from 'react-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container
} from 'reactstrap';
import classnames from 'classnames';
import "./css/sidebar.scss";
//Images
import logoImg from '../../assets/images/logo.png';
import homeImg from '../../assets/images/home.png';
import tradeImg from '../../assets/images/trade.png';
import farmsImg from '../../assets/images/farms.png';
import stakeImg from '../../assets/images/stake.png';
import moreImg from '../../assets/images/more.png';
import fbImg from '../../assets/images/fb.png';
import twitterImg from '../../assets/images/twitter.png';
import instaImg from '../../assets/images/insta.png';
import discordImg from '../../assets/images/discord.png';
import youtubeImg from '../../assets/images/youtube.png';
import collarQuestLogo from '../../assets/images/favicon.ico';
import axios from 'axios';


const Sidebar = (props) => {
  const [data, setData] = useState([])

  const {

  } = props;

  useEffect(() => {
    axios.get("https://api.collarswap.io/api/sitesettings/getsitesettings")
      .then((res) => {
        setData(res.data.result)
      })
  }, [])


  const [isOpen, setIsOpen] = useState(false);
  const toggleNav = () => setIsOpen(!isOpen);

  var toggleswitch = props.openSidebar

  return (
    <Fragment isOpen={isOpen} >
      <div className={toggleswitch ? "side-nav" : "side-nav fliph"} >
        <div className='sidebar-page '>
          <img src={logoImg} className='mt-3 mx-auto d-block' />
          <div className="menu-cont  mt-3">
            <ul className="menu-ul">
              <li>
                <Link to="/" onClick={() => {
                  setTimeout(() => {
                    window.location.reload()
                  }, 100);

                }}><img src={homeImg} /><span>Home</span></Link>
              </li>
              <li className="">
                <Link to="/swap"> <img src={tradeImg} />Swap</Link>
              </li>
              <li className="">
                <Link to="/liquidity"> <img src={farmsImg} />Liquidity</Link>
              </li>
              <li>
                <Link to="/lockstakemain"><img src={stakeImg} />Lock Stake</Link>
              </li>
              <li>
                <Link to="/flexstake"><img src={tradeImg} />Flex Stake</Link>
              </li>
              <li style={{ marginTop: '-11px'}}>
                <Link onClick={ () => window.open("https://collarquest.com/") }><img src={ collarQuestLogo } style={{ minHeight: '20px', maxHeight: '20px'}}/>Collar Quest  </Link>
              </li>
              {/* <li>
                <Link to="/Lockstakev2"><img src={tradeImg} />Lock Stake v2</Link>
              </li> */}

            </ul>
          </div>
        </div>

        <div className='social-div  '>
          <div className='social-media-icons '>
            <a href={data.facebookURL}><img src={fbImg} /></a>
            <a href={data.twitterURL}><img src={twitterImg} /></a>
            <a href={data.instagramURL}><img src={instaImg} /></a>
            <a href={data.discordURL}><img src={discordImg} /></a>
            <a href={data.youtubeURL}><img src={youtubeImg} /></a>
          </div>
          <p>© <a href={data.tagdaoURL}>{data.copyRightsText}</a>. All rights reserved.</p>

        </div>
      </div>
    </Fragment>
  );
  // }
}

export default Sidebar;
