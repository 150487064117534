// export const Router_Address = "0x07A5088C243271eF76dCAcA154F3B56d0cd4538B";

// export const  Factory_Address = "0xFBdD01028294272759A416E3FF0C92cc60836F7A";

// export const Approve_Address = "0x30BcfD46ea42AB6764f758fed78B36D58d3d7939";

// export const Collar_Address = "0x9164AAA87592CcB3F85157A2C056179B4B4ed954"



// export const Weth_Address = "0x30BcfD46ea42AB6764f758fed78B36D58d3d7939";

// export const Stake_Address = "0xEED134Ec8E7c924c51238829859381f57b8961aC"

// ///////

// export const Collar_Sample_Address = "0x7912fF9C05C8ef2437d89A068ec189C485893DA9"

// export const Approve2_Address = "0x27A612f22105f7efe63E90f8a61125B4F2E88113";


// // export const Gem_Address = "0xa679f7E27f4Ccd1694C03192b935AB2346F2c618"

// export const Gem_Address = "0x92Ec82B56F30254157D4483FC9Bc25b18cE31D0F"

// export const Tag_Address = "0x93cec98cd264EABDb497b52c0Cf29685681d5490"


//////Goerli network///////////// 
// export const Router_Address = "0xa7034255ad58e19c35c986ba5066316bd110f3a9";

//  export const  Factory_Address = "0xde59195fc4d867e2ceebd755886c0ad61db21c47";

//   export const Collar_Address = "0xd9bfe51ce6fbd070c3e0447d5c1edf89aeed0ded"

//  export const Stake_Address = "0x05956fba84f425d578ad5d9e58e9ffd7797f074d"

//   export const Weth_Address = "0x6bc972589d1f5d460461eb21e76ab3a483b35456";



  //// live contract ///////////

  export const Gem_Address = "0xeBC27d9Bd8AC268934784Dcdf1eaA10dFaF97A9F"


  export const Tag_Address = "0x7797C85b46F548eAcC07C229f6Cd207d6370442f"

  export const Router_Address = "0xc67f50f4a93147268d45f5cd7e444ec94eb046c7";

 export const  Factory_Address = "0xe15b4d98af270161219d2349f160dfdee7abce40";

  // export const Collar_Address = "0x9783b81438c24848f85848f8df31845097341771"

  export const Collar_Address = "0x9783B81438C24848f85848f8df31845097341771"

 export const Stake_Address = "0x23F4F88FF6604114BEa9F6fAf60D69A4A833Cd4A"

  // export const Weth_Address = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";

export const Weth_Address = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"

// export const Flexstake_Address = "0x6030804493bf035fFAafFdb4118412737A48348E"

export const Flexstake_Address = "0x35ffff6e57d3b9652568f98fbcacff237d022a3c" 

export const LockStake_Address = "0x822e52b611c7a8b74dff1525098f95492bafec75"






