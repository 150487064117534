/*eslint-disable*/
import React, { Fragment, Component, useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from "react-router-dom";
import ReactDOM from "react-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
//import classnames from 'classnames';
import "./css/header.scss";
import WalletConnectProvider from "@walletconnect/web3-provider";

//Images
import logomobImg from "../../assets/images/logomob.png";
import gemImg from "../../assets/images/gem.png";
import dclogoImg from "../../assets/images/dclogo.png";
import sidetoggleImg from "../../assets/images/sidetoggle.png";
import metamaskImg from "../../assets/images/metamask.png";
import walconImg from "../../assets/images/walcon.png";
import tagb from "../../assets/images/tagb.png";

import QRCodeModal from "@walletconnect/qrcode-modal";


import Sidebar from "../../common/Sidebar/Sidebar";

import Approve_ABI from "../../ABI/Approve_ABI.json"
import { Collar_Address, Gem_Address, Tag_Address } from "../Contract_cons";
import tag from "../../assets/images/tag.png"
import { toast } from "react-toastify";




const Web3 = require("web3");
var web3 = new Web3(window.ethereum);

const Header = (props) => {
  const history = useHistory()


  const [isOpen, setIsOpen] = useState(false);
  const toggleNav = () => setIsOpen(!isOpen);


  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);


  const [modal8, setModal8] = useState(false);
  const toggle8 = () => setModal8(!modal8);


  const [modal10, setModal10] = useState(false);
  const toggle10 = () => setModal10(!modal10);

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const [modal12, setModal12] = useState(false);
  const toggle12 = () => setModal12(!modal12);

  const [modal111, setModal111] = useState(false);
  const toggle111 = () => setModal111(!modal111);

  const [message, setMessage] = useState("Error")
  const [test, setTest] = useState(1)


  useEffect(() => {
    let address = JSON.parse(window.localStorage.getItem("metaAddress"));

    if (address != "" && address != undefined) {
      var contract = new web3.eth.Contract(Approve_ABI, Tag_Address);
      var contract1 = new web3.eth.Contract(Approve_ABI, Gem_Address)
      var contract2 = new web3.eth.Contract(Approve_ABI, Collar_Address)

      contract.methods
        .balanceOf(address)
        .call()
        .then((tokenbal) => {
          var tokenbal_ori = tokenbal / Math.pow(10, 18);
          var newval = tokenbal_ori.toFixed(4);
          localStorage.setItem("Cbalance", JSON.stringify(newval));
        })

      contract1.methods.balanceOf(address).call()
        .then((tokenbal) => {
          var tokenbal_ori = tokenbal / Math.pow(10, 18);
          var newval = tokenbal_ori.toFixed(4);
          localStorage.setItem("Ebalance", JSON.stringify(newval));

        })

      contract2.methods.balanceOf(address)
        .call()
        .then((tokenbal) => {
          var tokenbal_ori = tokenbal / Math.pow(10, 18);
          var newval = tokenbal_ori.toFixed(4);
          localStorage.setItem("CCbalance", JSON.stringify(newval));
        })


    }

  }, [test])


  const connectWallet = async () => {
    // const provider = new WalletConnectProvider({
    //   infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
    // });

    // try {
    //   await provider.enable();
    //   const accounts = await web3.eth.getAccounts();
    // }
    // catch (e) {

    // }
    // console.log(window)
    //  const WalletConnectProvider = window.WalletConnectProvider.default;


    // const provider = new WalletConnectProvider({
    //   rpc: {
    //     1: "https://mainnet.infura.io/v3/",
    //     3: "https://ropsten.mycustomnode.com",
    //     100: "https://dai.poa.network",
    //     // ...
    //   },
    // });

    // await provider.enable();



    const bridge = "https://mainnet.infura.io/v3/"
    QRCodeModal.open(bridge);
    console.log(window)

    // const WalletConnectProvider = window.WalletConnectProvider.default;
    console.log("dfsdfsf")

    const mainProvider = new WalletConnectProvider({
      networkId: 1,
      rpc: {
        1: "https://mainnet.infura.io/v3/"
        // rpc: { 97: "https://data-seed-prebsc-1-s1.binance.org:8545/", 56: "https://bsc-dataseed1.binance.org", 1: "https://bsc-dataseed1.binance.org", 2: "https://rpc-mumbai.maticvigil.com/" 
      },
      qrcode: true,
      supportChainIds: [80001],
      pollingInterval: 12000,
      qrcodeModalOptions: {
        mobileLinks: [
          "rainbow",
          "metamask",
          "argent",
          "trust",
          "imtoken",
          "pillar",
        ],
        desktopLinks: ["encrypted ink"],
      },
    });
    await mainProvider.enable();
    web3 = new Web3(mainProvider);
    mainProvider.on("connect", () => {

    });

    QRCodeModal.close(bridge);
    let accounts = await web3.eth.getAccounts()
    localStorage.setItem("metaAddress", JSON.stringify(accounts[0]));

    var contract1 = new web3.eth.Contract(Approve_ABI, Gem_Address)
    contract1.methods.balanceOf(accounts[0]).call().then((bal) => {
      var test = bal / Math.pow(10, 18)
      test = test.toFixed(6)
      localStorage.setItem("Ebalance", JSON.stringify(test));

    })
    var contract = new web3.eth.Contract(Approve_ABI, Collar_Address);
    contract.methods
      .balanceOf(accounts[0])
      .call()
      .then((tokenbal) => {
        var tokenbal_ori = tokenbal / Math.pow(10, 18);
        var newval = tokenbal_ori.toFixed(6);
        localStorage.setItem("CCbalance", JSON.stringify(newval));
      })
    setTest(test + 1)

    setTimeout(() => {
      // setMessage("Metamask Connected Successfully")
      // toggle10()
      window.location.reload()
      toast.success("Metamask Connected Successfully")

    }, 1000);

  }


  const connectMetaMask = async () => {
    toggle()

    if (typeof window.ethereum !== "undefined") {


      try {
        const netId = await window.ethereum.request({ method: "eth_chainId" });
        if (parseInt(netId, 16) == 1) {
          await window.ethereum.enable();
          await window.ethereum.request({ method: "eth_requestAccounts" })
          const accounts = await web3.eth.getAccounts();
          var balance = await web3.eth.getBalance(accounts[0]);
          balance = await (balance / Math.pow(10, 18)).toFixed(4);
          var contract1 = new web3.eth.Contract(Approve_ABI, Gem_Address)
          contract1.methods.balanceOf(accounts[0]).call().then((bal) => {
            var test = bal / Math.pow(10, 18)
            test = test.toFixed(6)
            localStorage.setItem("Ebalance", JSON.stringify(test));

          })

          let acc = accounts[0].toLowerCase()
          localStorage.setItem("metaAddress", JSON.stringify(acc));

          var contract = new web3.eth.Contract(Approve_ABI, Collar_Address);
          contract.methods
            .balanceOf(accounts[0])
            .call()
            .then((tokenbal) => {
              var tokenbal_ori = tokenbal / Math.pow(10, 18);
              var newval = tokenbal_ori.toFixed(6);
              localStorage.setItem("CCbalance", JSON.stringify(newval));
            })

          setTest(test + 1)

          setTimeout(() => {

            window.location.reload()
            toast.success("Metamask Connected Successfully")

          }, 1000);

        } else {
          // setMessage("select Ethereum Mainnet Network")
          // toggle10()
          toast.error("select Ethereum Mainnet Network")
          setTimeout(() => {
            window.location.reload()

          }, 1000);
          return false;
        }
      } catch (e) {
        toast.error("No Metamask Detected")
        // setMessage("No metamsk Detected")
        // toggle10()

      }
    }
  };

  const disconnectMetaMask = async () => {
    localStorage.clear()
    history.push("/")

    toggle8()
    setTimeout(() => {
      toast.error("Metamsk Disconnected ")
      // setMessage("Metamask successfully disconnected ")
      // toggle10()
    }, 1000);

  }
  let address = localStorage.getItem("metaAddress");
  let collar = localStorage.getItem("Cbalance")
  let weth = localStorage.getItem("Ebalance")
  let cc = localStorage.getItem("CCbalance")






  if (address) {
    window.ethereum.on('networkChanged', function (networkId) {
      if (networkId !== 5) {

        localStorage.clear()
        history.push("/")

        setTimeout(() => {
          window.location.reload()
            .then(() => {
              // setMessage("select Ethereum Mainnet Network ")
              // toggle10()
              toast.error("select Ethereum Mainnet Network")
            })
        }, 2000);
      }


    })

    window.ethereum.on('accountsChanged', function (accounts) {
      localStorage.clear()
      history.push("/")
      let acc = accounts[0].toLowerCase()
      localStorage.setItem("metaAddress", JSON.stringify(acc));

      window.location.reload()
    })
  }

  return (
    <Fragment>

      <header className="com-head">
        <Navbar color="light" light expand="lg">
          <Container className="p-0" >
            <div className="headimg d-lg-none" onClick={() => { props.toggleSidebar(!props.sidebarStatus) }} >
              <img src={sidetoggleImg} />
            </div>


            <NavbarBrand href="/" className="mr-0" >
              <img src={logomobImg} className="d-lg-none d-block" />
            </NavbarBrand>
            <NavbarToggler onClick={toggleNav} />
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto align-items-center" navbar>
                <NavItem>
                  <NavLink href="#">
                    <div className="headimg" >
                      <img src={gemImg} />
                    </div>
                    {weth === null || weth === "undefined" ? "0.0000" :
                      weth.slice(1).slice(0, -1)}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink >
                    <div className="headimg">
                      <img src={tag} />

                    </div>
                    {collar === null || collar === "undefined" ? "0.0000" :
                      collar.slice(1).slice(0, -1)}                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#">
                    <div className="headimg">
                      <img src={dclogoImg} />
                    </div>
                    {cc === null || cc === "undefined" ? "0.0000" :
                      cc.slice(1).slice(0, -1)}
                  </NavLink>
                </NavItem>
                {address === null || address === "undefined" ? (
                  <button className="btn con-wal-btn" onClick={toggle}>
                    Connect Wallet
                  </button>
                ) : (
                  <button className="btn con-wal-btn " onClick={toggle8}>{address.slice(1, 5) + "....." + address.slice(39, 43)}</button>
                )}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </header>
      <Modal isOpen={modal} toggle={toggle} className="con-wal-modal">
        <ModalHeader toggle={toggle}>Wallet</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-12">
              <div className="wal-list mb-2" onClick={connectMetaMask}>
                <img src={metamaskImg} className="mr-2" />
                <span>Metamask</span>
              </div>
              <div className="wal-list mb-2" onClick={connectWallet}>
                <img src={walconImg} className="mr-2" />
                <span>Wallet Connect</span>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={modal8} toggle={toggle8} className="con-wal-modal">
        <ModalHeader toggle={toggle8}>Are you sure want to disconnect?</ModalHeader>
        <ModalBody>
          <div className="col-lg-12 d-flex">
            <button className="col-lg-6 btn  btn-success" onClick={() => toggle8()}> Cancel</button>
            <button className="col-lg-6 btn btn-danger" onClick={disconnectMetaMask}>Disconnect</button>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={modal10} toggle={toggle10} className="comingsoon-poup">
        {/* <ModalHeader toggle={toggle10}>Alert</ModalHeader> */}
        <ModalBody>
          <div class="text-center">
            {/* <h3>KOVEN TESTNET EARLY ACCESS</h3> */}
            <h5 style={{ fontSize: "45px" }}>{message}</h5>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
  // }  
};

export default Header;
