import React, {
  Component,
  Fragment,
  useState,
  useRef,
  useEffect,
  createRef,
} from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { toast } from "react-toastify";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import "./css/stake.scss";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/Sidebar/Sidebar";
import copy from "../../assets/images/copy.png"
import { Modal, ModalHeader, ModalBody, Collapse } from "reactstrap";
import stakedog2 from "../../assets/images/stakedog2.png"
import dogswapleftImg from "../../assets/images/dogswapleft.png";
import dogswaprightImg from "../../assets/images/dogswapright.png";
import stakedogImg from "../../assets/images/stakedog.png";
import metamask from "../../assets/images/metamask.png";

import {
  Collar_Sample_Address,
  Stake_Address,
} from "../../common/Contract_cons";
import Approve_ABI from "../../ABI/Approve_ABI.json";
import Stake_ABI from "../../ABI/Stake_ABI.json";

import Web3 from "web3";
import axios from "axios";
import Countdown from "react-countdown";
import LoadingOverlay from "react-loading-overlay";
import newdoge2 from "../../assets/images/newdoge2.png";
import newdoge1 from "../../assets/images/newdoge.png";


const web3 = new Web3(window.ethereum);

const Stake = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [load, setLoad] = useState(false)
  const loading = () => setLoad(!load)

  const [loadMsg, setLoadMsg] = useState("")


  const [modal55, setModal55] = useState(false);
  const toggle55 = () => setModal55(!modal55);

  const [modal33, setModal33] = useState(false);
  const toggle33 = () => setModal33(!modal33);

  const [message, setMessage] = useState();

  const [range, setRange] = useState();


  const [dbList, setDbList] = useState([]);
  const [pairId, setPairId] = useState([]);
  const [depAmount, setDepAmount] = useState([{ status: "empty" }]);

  const [pairAddress, setPairAddress] = useState();
  const [pairName, setPairName] = useState();
  const [pairPoolId, setPairPoolId] = useState();
  const [availableLp, setAvailableLp] = useState();
  const [valAmount, setValAmount] = useState();

  const [triggerCall, SetTrigger] = useState(true);

  const [dataLength, setDataLength] = useState();

  const [sidebarStatus, setSidebarStatus] = useState(false)

  ///
  const [apiData, setApiData] = useState([])
  const [HistoryData, setHistoryData] = useState([])
  const [render, setRender] = useState(1)

  const [stakeDays, setStakeDays] = useState("0")
  ///

  const history = useHistory();

  var gas_Price;

  var address = JSON.parse(window.localStorage.getItem("metaAddress"));

  var staking_fn = new web3.eth.Contract(Stake_ABI, Stake_Address);

  useEffect(() => {
    if (address != "" && address != undefined) {
      loading()
      axios
        .get("https://api.collarswap.io/api/stake/liststakedata")
        .then((res) => {
          // setDbList(res.data.result);
          getValues(res.data.result);
          // var arr = [];
          // for (var i = 0; i < res.data.result.length; i++) {
          //   arr.push(res.data.result[i].pairId);
          // }
          setLoadMsg("Loading")
          //  setPairId(arr);
        });
    }


  }, [triggerCall, address, render]);

  useEffect(() => {
    if (address != "" && address != undefined) {
      let add = {
        userAddress: address
      }
      axios.post("https://api.collarswap.io/api/stake/findByStatus", add)
        .then((res) => {
          let dat = res.data.message
          var array = [...dat]
          dat.map((da, i) => {
            staking_fn.methods.pendingReward(da.poolId, da.pairId).call()
              .then((bal) => {
                let balance = bal / 1000000000000000000
                array[i].reward = balance
                let duh = Math.floor(Date.now() / 1000)
                if (da.unStakeTime === "" || da.unStakeTime === null || da.unStakeTime === undefined) {
                  array[i].unStakeTimer = ""
                  // console.log(array[i].unstakeTimer,typeof array[i].unstakeTimer)
                }
                else {
                  if (da.unStakeTime > duh) {
                    let date = Math.floor(Date.now() / 1000)
                    let uns = da.unStakeTime
                    let end = (uns - date)
                    // console.log(end)
                    // array[i].unStakeTimer = end
                    array[i].unStakeTimer = secondsToDhms(end)
                  }
                  else {
                    array[i].unStakeTimer = ""
                  }



                }
              })
              .catch((err) => {
                array[i].reward = "0"
              })
          })
          setTimeout(() => {
            console.log(array)
            // if(array.length<=0){
            //   toast.error("Rerouting to Flex Stake")

            //   history.push("/flexstake")
            // }
            // else{
            //   toast.warning("New Lock Stake Coming Soon")
              setHistoryData(array)

            // }

          }, 1000)
        })
    }
  }, [render])


  const getValues = (data) => {

    var array = [...data]
    data.forEach((item, i) => {
      var contract = new web3.eth.Contract(Approve_ABI, item.pairAddress)

      contract.methods.balanceOf(address).call().then((bal) => {
        contract.methods.allowance(address, Stake_Address).call().then((allow) => {
          var balance = web3.utils.fromWei(bal, 'ether')
          console.log(balance) 
          var bb = balance.slice(0,6)
          array[i].balance = bb
          if (allow < 1157920892373161954235709850086879) {
            array[i].approve = "false"
          }
          else {
            array[i].approve = "true"

          }
          if (data.length - 1 == i) {
            setTimeout(() => {
              setApiData(array)
              setLoad(false)
            }, 2000);
          }

        })


      })
        .catch((err) => {
          array[i].balance = "0"
        })

    })
    // setTimeout(() => {
    //   setApiData(array)
    //           setLoad(false)
    // }, 2000);

  }

  const withdraw = (data) => {

    if (address != "" && address != undefined) {

      setLoadMsg("Withdrawing....")
      setLoad(true)

      staking_fn.methods
        .withdraw(data.poolId, data.pairId)
        .send({ from: address, gasPrice: gas_Price })
        .on("receipt", (hash) => {

          let dat = {
            pairId: data.pairId,
            poolId: data.poolId,
            userAddress: address,
            withDrawTime: "",
            type: "withdraw",
          }
          axios.post("https://api.collarswap.io/api/stake/updateStakeData", dat)
            .then((res) => {
              toast.success("Withdrawn Successfull")
              setLoad(false)
              setRender(render + 1)

            })
            .catch((err) => {
              toast.error("Something went Wrong")
              setLoad(false)

            })
        })
        .on("error", (hash) => {
          setLoad(false)
          toast.error("Transaction Cancelled")
        })
    }
    else {
      toast.error("Connect metaMask")
    }

  };

  function containsOnlyNumbers(str) {
    return /^[0-9]+$/.test(str);
  }

  const enableContract = async (data) => {

    let a = containsOnlyNumbers(stakeDays)
    if (a) {

      if (address != "" && address != undefined) {
        setPairAddress(data.pairAddress);
        setPairName(data.pair);
        setPairPoolId(data.pairId);

        var approve_fn = await new web3.eth.Contract(
          Approve_ABI,
          data.pairAddress
        );
        approve_fn.methods
          .balanceOf(address)
          .call()
          .then((amount) => {
            approve_fn.methods.decimals().call()
              .then((decimal) => {

                amount = amount / Math.pow(10, decimal);
                amount = amount.toFixed(2);
                amount = +amount;
                setValAmount("");
                setAvailableLp(amount);
                if (amount !== 0) {
                  if (stakeDays !== 0 && stakeDays !== "" && stakeDays !== undefined && stakeDays !== null) {
                    toggle33();
                  }
                  else {
                    toast.error("Please Fill some Days")
                  }
                } else {
                  toast.error("LP Not Available")
                  // setMessage("LP Not Available");
                  // toggle55();
                }
              })
          });
      } else {
        toast.error("Connect MetaMask")
        // setMessage("wallet not connected");
        // toggle55();
      }
    }
    else {
      toast.error("Enter valid Number")
    }


  };

  const stake = () => {

    toggle33();
    setLoadMsg("Staking....")
    setLoad(true)
    var approve_fn = new web3.eth.Contract(Approve_ABI, pairAddress);

    if (address !== "" && address !== undefined) {
      if (
        valAmount != "" &&
        parseFloat(valAmount) <= parseFloat(availableLp) &&
        parseFloat(valAmount) != 0
      ) {
        approve_fn.methods.decimals().call().then((decimal) => {
          let amount2

          if (valAmount < 1) {
            let test = valAmount.toString()
            let test2 = test.slice(0, -1)

            amount2 = test2
          }
          else {
            amount2 = Math.floor(valAmount).toString()
          }


          var amount1 = web3.utils.toWei(amount2, 'ether');


          staking_fn.methods
            .stake(pairPoolId, amount1, stakeDays)
            .send({ from: address, gasPrice: gas_Price })
            .on("receipt", (hash) => {
              setTimeout(() => {



                let pairId = hash.events.stakeing.returnValues.stakeID
                let stakeTime = hash.events.stakeing.returnValues.stakeTime
                let unstakeTime = parseInt(hash.events.stakeing.returnValues.stakeTime)

                transHistory(
                  pairPoolId,
                  pairId,
                  stakeTime,
                  unstakeTime,
                  "",
                  amount2,
                  hash.blockHash
                );
                setLoad(false)
                // SetTrigger(!triggerCall);
              }, 100);
            })

            .on("error", (error) => {
              setLoad(false)
              toast.error("Transaction Cancelled")

            });

        })
      } else {
        setLoad(false)
        toast.error("Transaction Cancelled")
      }
    } else {
      setLoad(false)
      toast.error("Transaction Cancelled")
    }
  };
  const transHistory = (
    poolId, pairId, stakeTime, unStakeTime, withDrawTime, amount, hash
  ) => {

    var data = {
      poolId: poolId,
      pairId: pairId,
      stakeTime: stakeTime,
      unStakeTime: unStakeTime,
      withDrawTime: withDrawTime,
      unStakeStatus: false,
      withDrawStatus: false,
      userAddress: address,
      stakeAmount: amount,
      pairName: pairName,
    }


    axios.post("https://api.collarswap.io/api/stake/insertStakeData", data)
      .then((res) => {


        //

        //

        toast.success("Staked Successfully")
        setRender(render + 1)
        setLoad(false)
      })
      .catch((err) => {
        setLoad(false)
        toast.error("Something went wrong")
      })

    const request = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        tx_id: hash,
        user_address: address,
        amount: amount,
        pair_name: pairName,
        pair_id: pairId,
        pair_address: pairAddress,
      }),
    };
    fetch(
      "http://api.collarswap.io/api/stake/poststakehistory", request
    )
      .then((data) => {
        // toast.success("Staked Successfully")
        // setLoad(false)

        // setRender(render + 1)
      })
      .catch((err) => {
        //  setLoad(false)

        //  toast.error("Something went Wrong")
      })



  };


  function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? " Day-" : " Day-") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " Hr-" : " Hr-") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " Min " : " Min ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
  }

  const unstake = (data) => {
    if (address !== "" && address !== undefined) {
      setLoadMsg("unstaking...")
      setLoad(true)

      staking_fn.methods
        .unstake(data.poolId, data.pairId)
        .send({ from: address, gasPrice: gas_Price })
        .on("receipt", (hash) => {

          let dat = {
            pairId: data.pairId,
            poolId: data.poolId,
            userAddress: address,
            unStakeTime: "",
            type: "unstake"
          }

          axios.post("https://api.collarswap.io/api/stake/updateStakeData", dat)
            .then((res) => {
              toast.success("Unstaked Successfully")
              setRender(render + 1)
              setLoad(false)

            })
            .catch((err) => {
              toast.success(" Something Went Wrong")
              setLoad(false)

            })
        })
        .on("error", (hash) => {
          setLoad(false)
          toast.error("Transaction Cancelled")
        })
    }
    else {
      toast.error("Connect MetaMask")
      setLoad(false)

    }
  };


  const rangeSet = (value) => {
    setRange(value);
    if (value == 100) {
      setValAmount(availableLp)
    }
    else {
      var val = (availableLp * value / 100);
      let values = val.toFixed(2)
      setValAmount(values);
    }

  };

  const amountConvert = (amount, decimal = 18, type = "towei") => {
    if (type == "towei") {
      let coinwei = Math.pow(10, decimal);
      let sendAmount = amount * coinwei;
      return (sendAmount = getNumber(sendAmount));
    } else if (type == "fromwei") {
      let coinwei = Math.pow(10, decimal);
      let sendAmount = amount / coinwei;
      return (sendAmount = getNumber(sendAmount));
    }
  };

  const getNumber = (x) => {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }

    return x.toString();
  };

  const harvest = (data) => {

    staking_fn.methods
      .claimReward(data.poolId, data.pairId)
      .send({ from: address })
      .on("receipt", (hash) => {
        toast.success("Claimed Successfully")
        setLoad(false)
        setRender(render + 1)


      })
      .on("error", (err) => {
        toast.error("Transaction Cancelled")
        setLoad(false)

      })

  };

  const addTokenToWallet = async (tokenDetails) => {
    let tokenData = {
      address: tokenDetails.pairAddress, // The address that the token is at.
      symbol: tokenDetails.pair, // A ticker symbol or shorthand, up to 5 chars.
      decimals: 18, // The number of decimals in the token
      image: tokenDetails.tokenImg, // A string url of the token logo
    };
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      window.ethereum
        .request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20", // Initially only supports ERC20, but eventually more!
            options: tokenData,
          },
        })
        .then((resp) => {
          toast.success("Token Added Successfully!");
        });
    } catch (error) {
      toast.error("Something went wrong.Please try again later.");
    }
  };

  const approve = (data) => {
    var approve_fn = new web3.eth.Contract(Approve_ABI, data.pairAddress);

    if (address !== "" && address !== undefined) {
      approve_fn.methods.approve(Stake_Address, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
        .send({ from: address, })
        .on("receipt", (hash) => {
          setRender(render + 1)
          toast.success("Pair Approved Successfully")
          setLoad(false)

        })
        .on("errror", (err) => {
          toast.error("Something Went Wrong")
          setLoad(false)

        })
    }
    else {
      toast.error("Connect MetaMask")
      setLoad(false)

    }
  }

  const copyToClipboard = async (add) => {

    try {
      await navigator.clipboard.writeText(add);
      toast.success("Copied Successfully")

    } catch (err) {
      console.error('Failed to copy: ', err);
    }

  }



  return (
    <Fragment>
      <LoadingOverlay active={load} spinner text={loadMsg} styles={{

        content: (base) => ({
          ...base,
          color: '#FEA501'
        }),
        spinner: (base) => ({
          ...base,
          margin: '200px auto 10px auto',
          width: '100px',
          '& svg circle': {
            stroke: '#FEA501'
          }
        }),
        overlay: (base) => ({
          ...base,
          background: 'rgb(0, 0, 0, 0.5)'
        })
      }}>
        <div className="cnt-load-div">
          <div className="container-fluid p-0">
            <div className="basic-layout">
              <div className="container">
                <div className="main-content-load-div">
                  <Sidebar openSidebar={sidebarStatus} />
                  <div className="main-content">
                    <Header toggleSidebar={setSidebarStatus} sidebarStatus={sidebarStatus} />
                    <div className="stake-page">
                      <div className="row mt-5 mb-4 mx-0">
                        <div className="col-lg-12">
                          <div className="swap-title-div p-3 row">
                            <img
                              src={newdoge2}
                              className="dog d-block img-fluid col-md-4"
                            />
                            <h3 className=" col-md-4">Stake</h3>
                            <img
                              src={newdoge1}
                              className="dog d-block img-fluid  col-md-4"
                            />
                          </div>
                        </div>
                      </div>
                      {HistoryData.length > 0 ? (
                        <>
                          <h3 className=" col-md-4 d-block" style={{ "color": "goldenrod", textAlign: 'center' }}>STAKED PAIR</h3>
                          <div className="clr_table_1 ">
                            <div className="table-responsive">
                              <table class="table table-borderless">
                                <thead className="tab_head_colr">
                                  <tr>
                                    <th>Pair</th>
                                    <th>Stake Amount</th>
                                    <th>Reward</th>
                                    <th>Harvest</th>
                                    <th>Withdraw</th>
                                    <th>Available In</th>

                                  </tr>

                                </thead>
                                {HistoryData && HistoryData.map((dat) => {
                                  return (
                                    <tbody className="tab_body_colr">

                                      <tr>
                                        <td>{dat.pairName}</td>
                                        <td>{dat.stakeAmount}</td>
                                        <td>{dat.reward}</td>
                                        <td>

                                          <button className="btn btn_tab_clr" onClick={() => harvest(dat)}>
                                            Harvest
                                          </button>
                                        </td>
                                        <td>
                                          {dat.unStakeStatus ? <button className="btn btn_tab_clr btn btn_tab_clr_1 " onClick={() => withdraw(dat)}>
                                            Withdraw
                                          </button> : <button className="btn btn_tab_clr btn btn_tab_clr_1 " disabled={dat.unStakeTimer ? true : false} onClick={() => unstake(dat)}>
                                            Unstake
                                          </button>}

                                        </td>
                                        {/* <td><Countdown date={Date.now() + dat.unStakeTimer} /></td> */}
                                        <td> {dat.unStakeTimer ? dat.unStakeTimer : "Available Now"} </td>

                                      </tr>

                                    </tbody>
                                  )
                                }
                                )}
                              </table>
                            </div>


                          </div>
                        </>
                      ) : null}
                      <div className="row mb-4">

                        {/* (): null} */}

                        {apiData && apiData.map((dat) => {
                          return (
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 ">
                              <div className="swap-liq-div px-3 px-md-4 py-2">
                                <div className="d-flex align-items-center justify-content-between titlediv mb-3 dog-imwrapper">
                                  <div className="go-wra">
                                    <img
                                      src={stakedog2}
                                      className="img-fluid"
                                    />
                                    <div className="coin-imwrapper">
                                      <img
                                        src={dat.fromTokenImg}
                                        className="img-fluid"
                                      />
                                      <img
                                        src={dat.toTokenImg}
                                        className="img-fluid"
                                      />
                                    </div>
                                  </div>
                                  <div class="tlRt">
                                    <span className="ml-0">{dat.pair}</span>
                                    <div class="tlMlPwr">{dat.APR}X</div>
                                  </div>
                                </div>

                                {/* <div class="stCdR">
                                  <div class="stCdC stCdLbl">APR:</div>
                                  <div class="stCdC  stCdVal">{dat.APR}</div>
                                </div> */}

                                <div class="stCdR">
                                  <div class="stCdC stCdLbl">Earn:</div>
                                  <div class="stCdC  stCdVal">TAG</div>
                                </div>

                                <div class="stCdR">
                                  <div class="stCdC stCdLbl">
                                    Available LP
                                  </div>
                                  <div style={{ wordBreak: "break-all" }} class="stCdC  stCdVal">{dat.balance}</div>
                                </div>
                                <div class="stCdR">
                                  <div class="stCdC stCdLbl">
                                    Stake Days <span style={{ color: "#fea501" }}>(max 365 days)</span>
                                  </div>
                                  <div class="stCdC  stCdVal">
                                    <input type="text"
                                      className="form-control"
                                      maxLength="3"
                                      id={dat.pairAddress}
                                      style={{ width: "65px" }} value={stakeDays}
                                      onChange={(e) => {
                                        var regex = new RegExp("^[0-9]*[.]?[0-9]*$")
                                        // if (regex.test(e.target.value)) {
                                        //   if (e.target.value > 365) {
                                        //     setStakeDays("365")
                                        //     document.getElementById(dat.pairAddress).value = "365";
                                        //   }
                                        //   else {
                                        //     if (e.target.value <= 0 && e.target.value !== "") {
                                        //       setStakeDays(1)
                                        //       document.getElementById(dat.pairAddress).value = "1";
                                        //     }
                                        //     else {
                                        //       setStakeDays(e.target.value)
                                        //       document.getElementById(dat.pairAddress).value = e.target.value;
                                        //     }
                                        //   }
                                        // }
                                        // else {
                                        //   toast.error("Please Fill Some Days")
                                        // }
                                      }} />
                                  </div>
                                </div>
                                <div class="stCdR" >
                                  <div class="stCdC stCdLbl" >
                                    <a href={"https://etherscan.io/address/" + dat.pairAddress} target={"_blank"}>Pair Info</a>
                                  </div>
                                  <div onClick={() => copyToClipboard(dat.pairAddress)} class="stCdC  stCdVal"><img style={{ width: "20px" }} src={copy} /></div>
                                </div>
                                <div class="stCdR" onClick={() => addTokenToWallet(dat)}>
                                  <div class="stCdC stCdVal"><img className="mr-3" style={{ width: "20px" }} src={metamask} />Add To Wallet</div>
                                </div>

                                {dat.approve === "false" ? <button disabled
                                  className="btn con-wal-btn w-100"
                                  onClick={() => approve(dat)}
                                >
                                  Enable
                                </button> : <button disabled
                                  className="btn con-wal-btn w-100"
                                  onClick={() => enableContract(dat)}
                                >
                                  Stake
                                </button>}



                              </div>
                            </div>
                          )
                        })}




                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={modal55} toggle={toggle55} className="settings-modal">
          <ModalHeader toggle={toggle55}>ALERT</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12">
                <div className="recent-trans-table">
                  <div className="table-responsive">
                    <p className="d-flex justify-content-center text-light ">
                      {message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={modal33}
          toggle={toggle33}
          className="sel-coin-modal PP-Stk"
        >
          <ModalHeader toggle={toggle33}>Stake</ModalHeader>
          <ModalBody>
            <div class="StkBdy">
              <div class="StkTTBl d-flex flex-wrap justify-content-between mb-3">
                <div class="">
                  <h4 class="FB_18fea">Total Stake(LP Tokens)<span style={{ color: "red" }} onClick={() => {
                    rangeSet(100);
                  }}>Max</span></h4>
                </div>
                <div class="">
                  <h4 class="FB_18ff">Balance : {availableLp}</h4>
                </div>
              </div>
              <div class="StkFrm mb-4">
                <input
                  type="text"
                  class="form-control"
                  value={valAmount}
                  onChange={(e) => {
                    const amount = e.target.value;
                    var regexp = new RegExp("^[0-9]*[.]?[0-9]*$");
                    if (regexp.test(amount)) {
                      setValAmount(amount);
                    }
                  }}
                />
              </div>
              <div class="row">
                <div class="col-lg-7 mb-4">
                  <div class="d-flex justify-content-around mb-2">
                    <button
                      type="button"
                      class="btn StkRbtn"
                      onClick={() => {
                        rangeSet(25);
                      }}
                    >
                      25%
                    </button>
                    <button
                      type="button"
                      class="btn StkRbtn"
                      onClick={() => {
                        rangeSet(50);
                      }}
                    >
                      50%
                    </button>
                    <button
                      type="button"
                      class="btn StkRbtn"
                      onClick={() => {
                        rangeSet(75);
                      }}
                    >
                      75%
                    </button>
                    <button
                      type="button"
                      class="btn StkRbtn"
                      onClick={() => {
                        rangeSet(100);
                      }}
                    >
                      100%
                    </button>
                  </div>
                </div>
                <div class="col-12 mb-4">
                  <h4 class="FB_18cb2">
                    Note : you cant unstake for {stakeDays} days period
                  </h4>
                </div>
                <div class="col-md-4 text-center mx-auto">
                  <button
                    type="button"
                    class="btn Stk-btn"
                    disabled={valAmount > availableLp ? true : false}
                    onClick={stake}
                  >
                    Stake
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </LoadingOverlay>
    </Fragment>
  );
};

export default Stake;
