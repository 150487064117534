import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import "./css/pagenotfound.scss";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/Sidebar/Sidebar";
import pnfImg from '../../assets/images/pnf.png';

import LoadingOverlay from "react-loading-overlay";
import newdoge2 from "../../assets/images/newdoge2.png";
import newdoge1 from "../../assets/images/newdoge.png";
import { toast } from "react-toastify";

const Pagenotfound = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  }
  const [sidebarStatus, setSidebarStatus] = useState(false)

  const history = useHistory()
  // useEffect(() => {
  //   toast.warning("New Flex Stake Coming Soon")
  // }, [])

  return (
    <Fragment>
      <div className="cnt-load-div">
        <div className="container-fluid p-0">
          <div className="basic-layout">
            <div className="container">
              <div className="main-content-load-div">
                <Sidebar  openSidebar={sidebarStatus} />
                <div className="main-content">
                  <Header toggleSidebar={setSidebarStatus} sidebarStatus={sidebarStatus}/>
                  <div className="stake-page">
                    <div className="row mt-5 mb-4 mx-0">
                      <div className="col-lg-12">
                        <div className="swap-title-div p-3 row">
                          <img
                            src={newdoge2}
                            className="dog d-block img-fluid col-md-4"
                          />
                          <h3 className=" col-md-4">Stake</h3>
                          <img
                            src={newdoge1}
                            className="dog d-block img-fluid  col-md-4"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      {/* <h1 className="text-white"> Notice </h1> */}
                    </div>
                    {/* <p className="text-white">     New stake will be available in flex stake soon. Previously staked user will be able to unstake from the lock stake  </p> */}
                    <div className="d-flex justify-content-center">
                      <div className="col-lg-">
                        <div className="cnt">
                          <button className="btn con-wal-btn mr-5" onClick={() => { history.push("/stake") }} >Lock V1</button>
                          <button className="btn con-wal-btn" onClick={() => { history.push("/Lockstakev2") }}>Lock V2</button>
                        </div>
                      </div>


                    </div>
                    {/* <div className="row mt-5 mb-4 mx-0">
                        <div className="col-lg-12">
                        <div className="cnt">
                          <h3>ooo</h3>
                          <img src={pnfImg} className="img-fluid d-block mx-auto" />
                          <button className="btn con-wal-btn">Home</button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );

}

export default Pagenotfound;