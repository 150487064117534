import React, {
  Component,
  Fragment,
  useState,
  useRef,
  useEffect,
  createRef,
} from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
} from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse,
  FormGroup,
} from "reactstrap";
import classnames from "classnames";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import "./css/liquidity.scss";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/Sidebar/Sidebar";
import metamask from "../../assets/images/metamask.png"
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";

// ABI files//
import Approve_ABI from "../../ABI/Approve_ABI.json";
import Factory_ABI from "../../ABI/Factory_ABI.json";
import Router_ABI from "../../ABI/Router_ABI.json";
import PairAddress_ABI from "../../ABI/PairAddress_ABI.json";

// contract cons //
import {
  Router_Address,
  Factory_Address,
  Weth_Address,
  Collar_Address,
  Gem_Address
} from "../../common/Contract_cons";
import newdoge2 from "../../assets/images/newdoge2.png";
import newdoge1 from "../../assets/images/newdoge.png";

import dogswapleftImg from "../../assets/images/dogswapleft.png";
import dogswaprightImg from "../../assets/images/dogswapright.png";
import downarrowImg from "../../assets/images/downarrow.png";
import exchangeImg from "../../assets/images/exchange.png";
import bnbImg from "../../assets/images/bnb.png";
import btcImg from "../../assets/images/btc.png";
import refreshImg from "../../assets/images/refresh.png";
import settingsImg from "../../assets/images/settings.png";
import appcImg from "../../assets/images/appc.png";
import dashImg from "../../assets/images/dash.png";
import xrmImg from "../../assets/images/xrm.png";
import usdtImg from "../../assets/images/usdt.png";
import actImg from "../../assets/images/act.png";
import cakeImg from "../../assets/images/cake.png";
import dclogosmallImg from "../../assets/images/dclogosmall.png";
import plusIcon from "../../assets/images/plusIcon.png"

import gem from "../../assets/images/gem.png"
import tag from "../../assets/images/tag.png"
import eth from "../../assets/images/eth.png"




const Web3 = require("web3");
const web3 = new Web3(window.ethereum);

const Swap = (props) => {
  toast.configure();

  const [isOpen, setIsOpen] = useState(false);
  const togglecol = () => setIsOpen(!isOpen);

  const [liquidityOpen, setLiquidityOpen] = useState(false);
  const toggle_hide = () => setLiquidityOpen(!liquidityOpen);

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [modal, setModal] = useState(false);
  const toggle1 = () => setModal(!modal);

  const [modal1, setModal1] = useState(false);
  const toggle2 = () => setModal1(!modal1);

  const [modal2, setModal2] = useState(false);
  const toggle3 = () => setModal2(!modal2);

  const [modal3, setModal3] = useState(false);
  const toggle4 = () => setModal3(!modal3);

  const [modal8, setModal8] = useState(false);
  const toggle8 = () => setModal8(!modal8);

  const [display, setDisplay] = useState(false);
  const displayClick = () => setDisplay(!display);

  //Loader

  const [fromTokenAddress, setFromTokenAddress] = useState(
    "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
  );
  const [toTokenAddress, setToTokenAddress] = useState();
  const [fromTokenName, setFromTokenName] = useState("ETH");
  const [toTokenName, setToTokenName] = useState("choose");
  const [fromTokenDecimal, setFromTokenDecimal] = useState(18);
  const [toTokenDecimal, setToTokenDecimal] = useState(18);

  const [fromTokenImg, setFromTokenImg] = useState()
  const [toTokenImg, setToTokenImg] = useState()

  // From and To value
  const [fromTokenValue, setFromTokenValue] = useState();
  const [toTokenValue, setToTokenValue] = useState();
  const [atokenperBtoken, setAtokenperBtoken] = useState(0);
  const [btokenperAtoken, setBtokenperAtoken] = useState(0);

  const [shareOfPool, setShareOfPool] = useState(0);

  //GWEI and Tolerance

  const [GWEI, setGWEI] = useState(5000000000);
  const [tolerance, setTolerance] = useState(0.1);
  const [txnDeadline, setTxnDeadline] = useState(2);

  //message
  const [message, setMessage] = useState("Error");
  //Loader
  const [load, setLoad] = useState(false);
  const loading = () => setLoad(!load);

  const [loadMsg, setLoadMsg] = useState("");

  //TOKEn LIST

  const [initialTokenList, setInitialTokenList] = useState([]);

  const [toInitialTokenList, setToInitialTokenList] = useState([]);

  const [tokenData, setTokenData] = useState([])

  //Liquidity
  const [liquidityDataVal, setLiquidityDataVal] = useState([]);
  const [historyData, setHistoryData] = useState([]);

  //Remove Liq
  const [fromRmvAddress, setFromRmvAddress] = useState();
  const [toRmvAddress, setToRmvAddress] = useState();
  const [lpRmvAddress, setLpRmvAddress] = useState();

  const [fromSym, setFromSym] = useState();
  const [toSym, setToSym] = useState();

  const [rmvFirstQuote, setRmvFirstQuote] = useState();
  const [rmvSecondQuote, setRmvSecondQuote] = useState();

  const [fromRmvValue, setFromRmvValue] = useState();
  const [toRmvValue, setToRmvValue] = useState();

  const [tokenBal, setTokenBal] = useState();


  //range
  const [range, setRange] = useState(100);
  const [dynamicFrmValue, setDynamicFrmValue] = useState();
  const [dynamicToValue, setDynamicToValue] = useState();
  const [dynamicTokenValue, setDynamicTokenValue] = useState();

  //Balance
  const [fromBalance, setFromBalance] = useState(0);
  const [toBalance, setToBalance] = useState(0);

  const [triggerCall, SetTrigger] = useState(true);

  const [fromDecimalToRemove, setFromDecimalToRemove] = useState();
  const [toDecimalToRemove, setToDecimalToRemove] = useState();

  const [sidebarStatus, setSidebarStatus] = useState(false)

  const [gas, setGas] = useState(0)


  const history = useHistory();

  var from_amount;
  var to_amount;
  var gas_Price;
  var total_supply;

  var address = JSON.parse(window.localStorage.getItem("metaAddress"));

  var Contract_Factory = new web3.eth.Contract(Factory_ABI, Factory_Address);

  var Contract_Router = new web3.eth.Contract(Router_ABI, Router_Address);

  var approve_fn1 = new web3.eth.Contract(Approve_ABI, fromTokenAddress);
  var approve_fn2 = new web3.eth.Contract(Approve_ABI, toTokenAddress);
  var liquidity_fn = new web3.eth.Contract(Router_ABI, Router_Address);

  useEffect(() => {
    axios.get("https://api.collarswap.io/api/liquidity/listliquidity")
      .then((respo) => {
        let data = respo.data.result
        let data1 = data.reverse()
        setHistoryData(data1);
      });
    axios.get("https://api.collarswap.io/api/currency/listcurrency")
      .then((res) => {
        setInitialTokenList(res.data.result);
        setTokenData(res.data.result)
        var arr = [];

        for (let i = 0; i < res.data.result.length; i++) {
          if (res.data.result[i].tokenName !== "ETH") {
            arr.push(res.data.result[i]);
          }
        }

        setToInitialTokenList(arr);
      });

    if (address != "" && address != undefined) {
      axios
        .get(
          `https://api.collarswap.io/api/liquidity/listuserliquidity/${address}`
        )
        .then(function (response) {
          if (response.data.status == true) {
            liquidityDataFn(response.data.result);
          } else {
          }
        })
        .catch(function (error) { });
    }

    if (address != "" && address != undefined) {
      web3.eth.getBalance(address).then((balc) => {
        let a = (balc / Math.pow(10, 18)).toFixed(6);
        setFromBalance(a);
      });
    }
  }, [address, triggerCall]);

  useEffect(() => {
    web3.eth.getGasPrice((e, gasPrice) => {

      let ga = web3.utils.fromWei(gasPrice, 'ether')
      setGas(ga)

    });
  }, [])

  const balanceChange = () => {
    var contract = new web3.eth.Contract(Approve_ABI, Collar_Address);
    var contract1 = new web3.eth.Contract(Approve_ABI, Gem_Address)

    contract.methods
      .balanceOf(address)
      .call()
      .then((tokenbal) => {
        var tokenbal_ori = tokenbal / Math.pow(10, 18);
        var newval = tokenbal_ori.toFixed(4);
        localStorage.setItem("Cbalance", JSON.stringify(newval));
      });

    // contract1.methods.balanceOf(address).call()
    //   .then((tokenbal) => {
    //     var tokenbal_ori = tokenbal / Math.pow(10, 18);
    //     var newval = tokenbal_ori.toFixed(4);
    //     localStorage.setItem("Ebalance", JSON.stringify(newval));

    //   })

    // web3.eth.getBalance(address).then((balance) => {
    //   balance = (balance / Math.pow(10, 18)).toFixed(4);
    //   localStorage.setItem("Ebalance", JSON.stringify(balance));
    // });
  };

  const selectFrom = (address1, name, decimal, img) => {
    if (address != undefined && address != "undefined" && address) {

      setFromTokenName(name);
      setFromTokenAddress(address1);
      setFromTokenDecimal(decimal);
      setFromTokenImg(img)
      let arr = [];
      let a = [...tokenData]
      a.map((data) => {
        if (data.tokenName !== name) {
          arr.push(data);
        }
      });
      setToInitialTokenList(arr);

      if (name == "ETH") {
        web3.eth.getBalance(address).then((balc) => {
          let a = (balc / Math.pow(10, decimal));
          let b = a.toFixed(6)
          setFromBalance(b);
        });
      } else {
        var contract = new web3.eth.Contract(Approve_ABI, address1);
        contract.methods
          .balanceOf(address)
          .call()
          .then((tokenbal) => {
            var tokenbal_ori = tokenbal / Math.pow(10, decimal);
            var newval = tokenbal_ori.toFixed(6);
            setFromBalance(newval);
          });
      }
    }
    else {
      setFromTokenName(name);
      setFromTokenAddress(address1);
      setFromTokenDecimal(decimal);
      setFromTokenImg(img)
    }
  };

  const selectTo = (address1, name, decimal, img) => {
    if (address != undefined && address != "undefined" && address) {

      setToTokenName(name);
      setToTokenAddress(address1);
      setToTokenDecimal(decimal);
      setToTokenImg(img)
      let arr = [];
      let a = [...tokenData]
      a.map((data) => {
        if (data.tokenName !== name) {
          arr.push(data);
        }
      });
      setInitialTokenList(arr);

      if (name == "ETH") {
        web3.eth.getBalance(address).then((balc) => {
          let a = (balc / Math.pow(10, decimal));
          let b = a.toFixed(6)
          setToBalance(b);
        });
      } else {
        var contract = new web3.eth.Contract(Approve_ABI, address1);
        contract.methods
          .balanceOf(address)
          .call()
          .then((tokenbal) => {
            var tokenbal_ori = tokenbal / Math.pow(10, decimal);
            var newval = tokenbal_ori.toFixed(6);
            setToBalance(newval);
          })
          .catch((err) => {
          })
      }
    }
    else {
      setToTokenName(name);
      setToTokenAddress(address1);
      setToTokenDecimal(decimal);
      setToTokenImg(img)
    }
  };

  const LiquidityPerToken = () => {
    if (address != undefined && address != "undefined" && address) {

      if (fromTokenAddress !== undefined && toTokenAddress !== undefined) {
        Contract_Factory.methods
          .getPair(fromTokenAddress, toTokenAddress)
          .call()
          .then((pair_address) => {
            if (pair_address !== "0x0000000000000000000000000000000000000000") {
              var testing = new web3.eth.Contract(PairAddress_ABI, pair_address);
              testing.methods
                .totalSupply()
                .call()
                .then((result) => {
                  total_supply = result;
                  testing.methods
                    .balanceOf(address)
                    .call()
                    .then((res) => {
                      let shareofpool = (res / total_supply);
                      shareofpool = shareofpool.toFixed(3) * 100

                      setShareOfPool(shareofpool);
                    });
                });

              //   Contract_Router.methods
              //     .quote("1000000000000000000", fromTokenAddress, toTokenAddress)
              //     .call()
              //     .then((result) => {
              //       var Per_A = result;
              //       var a = Per_A / Math.pow(10, toTokenDecimal);
              //       to_amount = a.toFixed(6);
              //       setAtokenperBtoken(to_amount);
              //     })
              //     .catch((err) => {
              //       setAtokenperBtoken(0)
              //     })

              //   Contract_Router.methods
              //     .quote("1000000000000000000", toTokenAddress, fromTokenAddress)
              //     .call()
              //     .then((result) => {
              //       var Per_A = result;
              //       var a = Per_A / Math.pow(10, fromTokenDecimal);
              //       to_amount = a.toFixed(6);
              //       setBtokenperAtoken(to_amount);
              //     })
              //     .catch((err) => {
              //       setBtokenperAtoken(0)
              //     })
              //   //
              var Contract_Pair = new web3.eth.Contract(PairAddress_ABI, pair_address)

              Contract_Pair.methods.getReserves().call().then((data) => {
                Contract_Pair.methods.token0().call().then((token0) => {
                  Contract_Pair.methods.token1().call().then((token1) => {


                    if (fromTokenAddress === token0) {
                      let data0 = data[0] / Math.pow(10, fromTokenDecimal)
                      let data1 = data[1] / Math.pow(10, toTokenDecimal)

                      let test = data1 / data0
                      test = test * 1
                      test = test.toFixed(6)
                      setAtokenperBtoken(test)

                    }
                    if (toTokenAddress === token0) {
                      let data0 = data[0] / Math.pow(10, toTokenDecimal)
                      let data1 = data[1] / Math.pow(10, fromTokenDecimal)

                      let test = data0 / data1
                      test = test * 1
                      test = test.toFixed(6)
                      setAtokenperBtoken(test)

                    }

                  })
                })



              })

              var Contract_Pair = new web3.eth.Contract(PairAddress_ABI, pair_address)
              Contract_Pair.methods.getReserves().call().then((data) => {
                Contract_Pair.methods.token0().call().then((token0) => {
                  Contract_Pair.methods.token1().call().then((token1) => {

                    if (fromTokenAddress === token0) {
                      let data0 = data[0] / Math.pow(10, fromTokenDecimal)
                      let data1 = data[1] / Math.pow(10, toTokenDecimal)

                      let test = data0 / data1
                      test = test * 1
                      test = test.toFixed(6)
                      setBtokenperAtoken(test)



                    }
                    if (toTokenAddress === token0) {
                      let data0 = data[0] / Math.pow(10, toTokenDecimal)
                      let data1 = data[1] / Math.pow(10, fromTokenDecimal)

                      let test = data1 / data0
                      test = test * 1
                      test = test.toFixed(6)
                      setBtokenperAtoken(test)

                    }

                  })
                })



              })

              ///////////////
            }
            else {
              // setMessage("Add pair for first Time");
              // toggle8();
              toast.warning("Add Pair For the First Time")
            }
          });
      }
    }
    else {
    }
  };

  const addLiquidity = async () => {
    var time = Math.floor(Date.now() / 1000);
    var time1 = txnDeadline;
    var time1 = time1 > 0 ? time1 * 600000 : 1 * 600000;
    var deadline = parseInt(time + 800).toString();

    if (GWEI == 0) {
      web3.eth.getGasPrice((e, gasPrice) => {
        gas_Price = gasPrice;
      });
    } else {
      gas_Price = GWEI + GWEI * (tolerance / 100);
    }
    if (fromTokenValue <= fromBalance && toTokenValue <= toBalance) {
      if (address != "" && address != undefined) {
        if (fromTokenName == "ETH") {
          setLoad(true);
          setLoadMsg("Adding Liquidity ....");

          let approve_fn = await new web3.eth.Contract(Approve_ABI, toTokenAddress)
          to_amount = amountConvert(toTokenValue, toTokenDecimal);
          from_amount = amountConvert(fromTokenValue, fromTokenDecimal);
          ///
          approve_fn.methods.allowance(address, Router_Address).call().then((res) => {

            if (res <= 1157920892373161954235709850086879) {
              approve_fn2.methods
                .approve(Router_Address, "115792089237316195423570985008687907853269984665640564039457584007913129639935")

                .send({ from: address})
                .on("receipt", (hash) => {
                  liquidity_fn.methods
                    .addLiquidityETH(
                      toTokenAddress,
                      to_amount,
                      0,
                      0,
                      address,
                      deadline
                    )
                    .send({ from: address, value: from_amount })
                    .on("receipt", (hash) => {
                      setTimeout(function () {
                        transHistory(
                          hash.blockHash,
                          address,
                          "add",
                          fromTokenName,
                          toTokenName,
                          fromTokenAddress,
                          toTokenAddress,
                          fromTokenValue,
                          toTokenValue
                        );
                        balanceChange();
                        setLoad(false);
                      }, 10000);
                    })
                    .on("error", (error) => {
                      setLoad(false);
                      // setMessage("Transaction Cancelled");
                      // toggle8();
                      toast.error("Transaction Cancelled")
                    });
                })
                .on("error", (error) => {
                  setLoad(false);
                  // setMessage("Transaction Cancelled");
                  // toggle8();
                  toast.error("Transaction Cancelled")

                });
            }
            else {

              liquidity_fn.methods
                .addLiquidityETH(
                  toTokenAddress,
                  to_amount,
                  0,
                  0,
                  address,
                  deadline
                )
                .send({ from: address, value: from_amount })
                .on("receipt", (hash) => {
                  setTimeout(function () {
                    transHistory(
                      hash.blockHash,
                      address,
                      "add",
                      fromTokenName,
                      toTokenName,
                      fromTokenAddress,
                      toTokenAddress,
                      fromTokenValue,
                      toTokenValue
                    );
                    balanceChange();
                    setLoad(false);
                  }, 10000);
                })
                .on("error", (error) => {
                  setLoad(false);
                  // setMessage("Transaction Cancelled");
                  // toggle8();
                  toast.error("Transaction Cancelled")
                });

            }
          })
          //


        } else if (toTokenName == "ETH") {
          setLoad(true);
          setLoadMsg("Adding Liquidity ....");
          let approve_fn = await new web3.eth.Contract(Approve_ABI, fromTokenAddress)
          to_amount = amountConvert(toTokenValue, toTokenDecimal);
          from_amount = amountConvert(fromTokenValue, fromTokenDecimal);
          ///
          approve_fn.methods.allowance(address, Router_Address).call().then((res) => {
            if (res <= 1157920892373161954235709850086879) {
              approve_fn1.methods
                .approve(Router_Address, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
                .send({ from: address })
                .on("receipt", (hash) => {
                  liquidity_fn.methods
                    .addLiquidityETH(
                      fromTokenAddress,
                      from_amount,
                      0,
                      0,
                      address,
                      deadline
                    )
                    .send({ from: address, value: to_amount })
                    .on("receipt", (hash) => {
                      setTimeout(function () {
                        transHistory(
                          hash.blockHash,
                          address,
                          "add",
                          fromTokenName,
                          toTokenName,
                          fromTokenAddress,
                          toTokenAddress,
                          fromTokenValue,
                          toTokenValue
                        );
                        setLoad(false);
                        balanceChange();
                      }, 10000);
                    })
                    .on("error", (error) => {
                      setLoad(false);
                      // setMessage("Transaction Cancelled ");
                      // toggle8();
                      toast.error("Transaction Cancelled")

                    });
                })
                .on("error", (error) => {
                  setLoad(false);
                  // setMessage("Transaction Cancelled ");
                  // toggle8();
                  toast.error("Transaction Cancelled")

                });
            }
            else {
              liquidity_fn.methods
                .addLiquidityETH(
                  fromTokenAddress,
                  from_amount,
                  0,
                  0,
                  address,
                  deadline
                )
                .send({ from: address, value: to_amount })
                .on("receipt", (hash) => {
                  setTimeout(function () {
                    transHistory(
                      hash.blockHash,
                      address,
                      "add",
                      fromTokenName,
                      toTokenName,
                      fromTokenAddress,
                      toTokenAddress,
                      fromTokenValue,
                      toTokenValue
                    );
                    setLoad(false);
                    balanceChange();
                  }, 10000);
                })
                .on("error", (error) => {
                  setLoad(false);
                  // setMessage("Transaction Cancelled ");
                  // toggle8();
                  toast.error("Transaction Cancelled")

                });
            }
          })
          ///


        } else {
          setLoad(true);
          setLoadMsg("Approving Tokens....");
          from_amount = fromTokenValue;
          to_amount = toTokenValue;
          from_amount = amountConvert(from_amount, fromTokenDecimal);
          to_amount = amountConvert(to_amount, toTokenDecimal);

          /////
          var approve_fn1 = await new web3.eth.Contract(Approve_ABI, fromTokenAddress);
          var approve_fn2 = await new web3.eth.Contract(Approve_ABI, toTokenAddress);


          approve_fn1.methods.allowance(address, Router_Address).call().then((app1) => {
            approve_fn2.methods.allowance(address, Router_Address).call().then(async (app2) => {
              if (app1 > 1157920892373161954235709850086879 && app2 > 1157920892373161954235709850086879) {

                ///
                liquidity_fn.methods
                  .addLiquidity(
                    fromTokenAddress,
                    toTokenAddress,
                    from_amount,
                    to_amount,
                    0,
                    0,
                    address,
                    deadline
                  )
                  .send({ from: address })
                  .on("receipt", (hash) => {
                    setTimeout(function () {
                      transHistory(
                        hash.blockHash,
                        address,
                        "add",
                        fromTokenName,
                        toTokenName,
                        fromTokenAddress,
                        toTokenAddress,
                        fromTokenValue,
                        toTokenValue
                      );
                      setLoad(false);
                      balanceChange();
                    }, 10000);
                  })
                  .on("error", (error) => {
                    setLoad(false);
                    setMessage("Transaction Cancelled");
                    toggle8();
                  });
                ///
              }
              else {
                await approve_fn1.methods
                  .approve(Router_Address, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
                  .send({ from: address })
                  .on("receipt", (receipt) => {
                    if (receipt.status) {
                      setLoadMsg("Adding Liquidity....");
                      approve_fn2.methods
                        .approve(Router_Address, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
                        .send({ from: address })
                        .on("receipt", (receipt) => {
                          if (receipt.status) {
                            liquidity_fn.methods
                              .addLiquidity(
                                fromTokenAddress,
                                toTokenAddress,
                                from_amount,
                                to_amount,
                                0,
                                0,
                                address,
                                deadline
                              )
                              .send({ from: address })
                              .on("receipt", (hash) => {
                                setTimeout(function () {
                                  transHistory(
                                    hash.blockHash,
                                    address,
                                    "add",
                                    fromTokenName,
                                    toTokenName,
                                    fromTokenAddress,
                                    toTokenAddress,
                                    fromTokenValue,
                                    toTokenValue
                                  );
                                  setLoad(false);
                                  balanceChange();
                                }, 10000);
                              })
                              .on("error", (error) => {
                                setLoad(false);
                                setMessage("Transaction Cancelled");
                                toggle8();
                              });
                          }
                        })
                        .on("error", (error) => {
                          setLoad(false);
                          setMessage("Transaction Cancelled ");
                          toggle8();
                        });
                    }
                  })
                  .on("error", (error) => {
                    setLoad(false);
                    setMessage("Transaction Cancelled ");
                    toggle8();
                  });
              }
            })
          })
          ////


        }
      } else {
        // setMessage("Connect Metamask");
        // toggle8();
        toast.warning("Connect Metamask")

      }
    }
    else {
      toast.info("Insufficient Amount Please Check From & To Token Balance.");
    }

  };

  const rangeSet = (value) => {
    if (value == 100) {
      setRange(value);

      setDynamicFrmValue(fromRmvValue)
      setDynamicToValue(toRmvValue)
      setDynamicTokenValue(tokenBal)
    }
    else {
      setRange(value);
      var val = (fromRmvValue * value) / 100;
      setDynamicFrmValue(val);
      var val1 = (toRmvValue * value) / 100;
      setDynamicToValue(val1);
      var val3 = (tokenBal * value) / 100;
      setDynamicTokenValue(val3);
    }
  };

  const removeLiquidityRemove = async () => {
    if (address != "" && address != undefined) {
      // var liquidityVal = (tokenBal * range) / 100;

      var liquidityVal = dynamicTokenValue

      if (liquidityVal < 1) {
        let test = liquidityVal.toString()
        let test2 = test.slice(0, -1)

        liquidityVal = test2
      }
      else {
        liquidityVal = Math.floor(liquidityVal).toString()
      }
      console.log(liquidityVal)
      const lpAddress = lpRmvAddress;
      const fromAddr = fromRmvAddress;
      const toAddr = toRmvAddress;
      var fromAmount = dynamicFrmValue;
      var toAmount = dynamicToValue;

      fromAmount = amountConvert(fromAmount, fromDecimalToRemove);
      toAmount = amountConvert(toAmount, toDecimalToRemove);
      liquidityVal = amountConvert(liquidityVal, 18);

      if (GWEI == 0) {
        web3.eth.getGasPrice((e, gasPrice) => {
          gas_Price = gasPrice;
        });
      } else {
        gas_Price = GWEI + GWEI * (tolerance / 100);
      }

      var time = Math.floor(Date.now() / 1000);
      var time1 = txnDeadline;
      var time1 = time1 > 0 ? time1 * 600000 : 1 * 600000;
      var deadline = parseInt(time + 800).toString();

      let contract1 = await new web3.eth.Contract(Router_ABI, Router_Address);
      let contract2 = await new web3.eth.Contract(PairAddress_ABI, lpAddress);

      //
      contract2.methods.allowance(address, Router_Address).call()
        .then((allow) => {
          if (allow > 1157920892373161954235709850086879) {

            //
            if (fromRmvAddress == Weth_Address) {
              setLoad(true);
              setLoadMsg("Removing Liquidity....");


              contract1.methods
                .removeLiquidityETH(toAddr, liquidityVal, 0, 0, address, deadline)
                .send({ from: address })
                .on("receipt", (hash) => {
                  setTimeout(function () {
                    transHistory(
                      hash.blockHash,
                      address,
                      "remove",
                      fromSym,
                      toSym,
                      fromAddr,
                      toAddr,
                      dynamicFrmValue,
                      dynamicToValue
                    );
                    setLoad(false);
                  }, 10000);
                })
                .on("error", (err) => {
                  //setMessage("Cancelled Transaction");

                  setLoad(false);
                  toast.error("Transaction Cancelled")

                  // toggle8();
                });

            }
            else if (toRmvAddress == Weth_Address) {
              setLoad(true);
              setLoadMsg("Removing Liquidity....");


              contract1.methods
                .removeLiquidityETH(fromAddr, liquidityVal, 0, 0, address, deadline)
                .send({ from: address })
                .on("receipt", (hash) => {
                  setTimeout(function () {
                    transHistory(
                      hash.blockHash,
                      address,
                      "remove",
                      fromSym,
                      toSym,
                      fromAddr,
                      toAddr,
                      dynamicFrmValue,
                      dynamicToValue
                    );
                    setLoad(false);
                  }, 10000);
                })
                .on("error", (err) => {
                  //setMessage("Cancelled Transaction");

                  setLoad(false);
                  toast.error("Transaction Cancelled")

                  // toggle8();
                });

            }
            else {
              setLoad(true);
              setLoadMsg("Removing Liquidity....");

              contract1.methods
                .removeLiquidity(
                  fromAddr,
                  toAddr,
                  liquidityVal,
                  0,
                  0,
                  address,
                  deadline
                )
                .send({ from: address})
                .on("receipt", (hash) => {
                  setTimeout(function () {
                    setLoad(false);
                    transHistory(
                      hash.blockHash,
                      address,
                      "remove",
                      fromSym,
                      toSym,
                      fromAddr,
                      toAddr,
                      dynamicFrmValue,
                      dynamicToValue
                    );
                  }, 10000);
                })
                .on("error", (err) => {
                  toast.error("Transaction Cancelled")

                  setLoad(false);
                });

            }
            //

          }
          else {
            if (fromRmvAddress == Weth_Address) {
              setLoad(true);
              setLoadMsg("Removing Liquidity....");

              contract2.methods
                .approve(Router_Address, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
                .send({ from: address })
                .on("receipt", (hash) => {
                  contract1.methods
                    .removeLiquidityETH(toAddr, liquidityVal, 0, 0, address, deadline)
                    .send({ from: address })
                    .on("receipt", (hash) => {
                      setTimeout(function () {
                        transHistory(
                          hash.blockHash,
                          address,
                          "remove",
                          fromSym,
                          toSym,
                          fromAddr,
                          toAddr,
                          dynamicFrmValue,
                          dynamicToValue
                        );
                        setLoad(false);
                      }, 10000);
                    })
                    .on("error", (err) => {
                      //setMessage("Cancelled Transaction");

                      setLoad(false);
                      toast.error("Transaction Cancelled")

                      // toggle8();
                    });
                })
                .on("error", (err) => {
                  //setMessage("Cancelled Transaction");
                  setLoad(false);
                  toast.error("Transaction Cancelled")

                  //toggle8();
                });
            }
            else if (toRmvAddress == Weth_Address) {
              setLoad(true);
              setLoadMsg("Removing Liquidity....");

              contract2.methods
                .approve(Router_Address, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
                .send({ from: address })
                .on("receipt", (hash) => {
                  contract1.methods
                    .removeLiquidityETH(fromAddr, liquidityVal, 0, 0, address, deadline)
                    .send({ from: address })
                    .on("receipt", (hash) => {
                      setTimeout(function () {
                        transHistory(
                          hash.blockHash,
                          address,
                          "remove",
                          fromSym,
                          toSym,
                          fromAddr,
                          toAddr,
                          dynamicFrmValue,
                          dynamicToValue
                        );
                        setLoad(false);
                      }, 10000);
                    })
                    .on("error", (err) => {
                      //setMessage("Cancelled Transaction");

                      setLoad(false);
                      toast.error("Transaction Cancelled")

                      // toggle8();
                    });
                })
                .on("error", (err) => {
                  //setMessage("Cancelled Transaction");
                  setLoad(false);
                  toast.error("Transaction Cancelled")

                  //toggle8();
                });
            }
            else {
              setLoad(true);
              setLoadMsg("Removing Liquidity....");
              contract2.methods
                .approve(Router_Address, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
                .send({ from: address })
                .on("receipt", (hash) => {
                  contract1.methods
                    .removeLiquidity(
                      fromAddr,
                      toAddr,
                      liquidityVal,
                      0,
                      0,
                      address,
                      deadline
                    )
                    .send({ from: address })
                    .on("receipt", (hash) => {
                      setTimeout(function () {
                        setLoad(false);
                        transHistory(
                          hash.blockHash,
                          address,
                          "remove",
                          fromSym,
                          toSym,
                          fromAddr,
                          toAddr,
                          dynamicFrmValue,
                          dynamicToValue
                        );
                      }, 10000);
                    })
                    .on("error", (err) => {
                      //setMessage("Cancelled Transaction");
                      toast.error("Transaction Cancelled")

                      setLoad(false);
                      // toggle8();
                    });
                })
                .on("error", (err) => {
                  //  setMessage("Cancelled Transaction");
                  toast.error("Transaction Cancelled")

                  setLoad(false);
                  // toggle8();
                });
            }
          }
        })

      //


    } else {
      // setMessage("Connect Metamask");
      // toggle8();
      toast.error("Connect Metamask")

    }
  };

  const amountConvert = (amount, decimal, type = "towei") => {
    if (type == "towei") {
      let coinwei = Math.pow(10, decimal);
      let sendAmount = amount * coinwei;
      return (sendAmount = getNumber(sendAmount));
    } else if (type == "fromwei") {
      let coinwei = Math.pow(10, decimal);
      let sendAmount = amount / coinwei;
      return (sendAmount = getNumber(sendAmount));
    }
  };

  const getNumber = (x) => {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }
    if (!Number.isInteger(x)) {
      x = x.toString();
    }

    return x.toString();
  };

  const fromSearch = (e) => {
    let a = [...tokenData]
    if (e.target.value !== "" && e.target.value !== undefined) {
      let token = e.target.value;
      let test = token.toLowerCase();
      var searchResult = a.filter(
        (word) => word.tokenName.toLowerCase().indexOf(test) > -1
      );
      setInitialTokenList(searchResult);
    } else {
      setInitialTokenList(a);
    }
  };

  const toSearch = (e) => {
    let a = [...tokenData]

    if (e.target.value !== "" && e.target.value !== undefined) {
      var keywordToSearch = e.target.value;
      var keyword = keywordToSearch.toLowerCase();
      var searchResult = a.filter(
        (word) => word.tokenName.toLowerCase().indexOf(keyword) > -1
      );
      setToInitialTokenList(searchResult);
    } else {
      setToInitialTokenList(a);
    }
  };

  const fromLiquidityData = (amount) => {
    if (address != undefined && address != "undefined" && address) {
      if (fromTokenAddress !== undefined && toTokenAddress !== undefined) {
        Contract_Factory.methods
          .getPair(fromTokenAddress, toTokenAddress)
          .call()
          .then((pair_address) => {
            if (pair_address == "0x0000000000000000000000000000000000000000") {
              // setMessage("Add pair for first Time");
              // toggle8();
              // setFromTokenValue();
              // setToTokenValue();
              setShareOfPool("0");
              setAtokenperBtoken("0");
              setBtokenperAtoken("0");
            }
            else {
              var Contract_Pair = new web3.eth.Contract(PairAddress_ABI, pair_address)
              Contract_Pair.methods.getReserves().call().then((data) => {
                Contract_Pair.methods.token0().call().then((token0) => {
                  Contract_Pair.methods.token1().call().then((token1) => {
                    if (fromTokenAddress === token0) {
                      let data0 = data[0] / Math.pow(10, fromTokenDecimal)
                      let data1 = data[1] / Math.pow(10, toTokenDecimal)

                      let test = data1 / data0
                      test = test * fromTokenValue
                      test = test.toFixed(6)
                      setToTokenValue(test)

                    }
                    if (toTokenAddress === token0) {
                      let data0 = data[0] / Math.pow(10, toTokenDecimal)
                      let data1 = data[1] / Math.pow(10, fromTokenDecimal)

                      let test = data0 / data1
                      test = test * fromTokenValue
                      test = test.toFixed(6)
                      setToTokenValue(test)

                    }

                  })
                })



              })
            }

            // if (pair_address != "0x0000000000000000000000000000000000000000") {
            //   from_amount = fromTokenValue;
            //   from_amount = amountConvert(from_amount, fromTokenDecimal);

            //   if (from_amount >= 1) {
            //     if (
            //       fromTokenAddress != undefined &&
            //       toTokenAddress != undefined
            //     ) {
            //       Contract_Router.methods
            //         .quote(from_amount, fromTokenAddress, toTokenAddress)
            //         .call()
            //         .then((result) => {
            //           var Per_A = result;
            //           var a = Per_A / Math.pow(10, fromTokenDecimal);
            //           amount = a.toFixed(6);
            //           setToTokenValue(amount);
            //         })
            //         .catch((err) => {
            //           setMessage("Something Went Wrong");
            //           toggle8();
            //           setFromTokenValue("")
            //           setToTokenValue("")
            //         })


            //     }
            //   } else {
            //     setToTokenValue("");
            //   }
            // }


          });
      }
    }
    else {

    }
  };

  const toLiquidityData = (amount) => {
    if (address != undefined && address != "undefined" && address) {

      if (fromTokenAddress !== undefined && toTokenAddress !== undefined) {
        Contract_Factory.methods
          .getPair(fromTokenAddress, toTokenAddress)
          .call()
          .then((pair_address) => {
            if (pair_address == "0x0000000000000000000000000000000000000000") {
              // setMessage("Add liqudidity for first time");
              // setFromTokenValue();
              // setToTokenValue();
              setShareOfPool("0");
              setAtokenperBtoken("0");
              setBtokenperAtoken("0");
            }
            else {
              var Contract_Pair = new web3.eth.Contract(PairAddress_ABI, pair_address)
              Contract_Pair.methods.getReserves().call().then((data) => {
                Contract_Pair.methods.token0().call().then((token0) => {
                  Contract_Pair.methods.token1().call().then((token1) => {

                    if (fromTokenAddress === token0) {
                      let data0 = data[0] / Math.pow(10, fromTokenDecimal)
                      let data1 = data[1] / Math.pow(10, toTokenDecimal)

                      let test = data0 / data1
                      test = test * toTokenValue
                      test = test.toFixed(6)
                      setFromTokenValue(test)



                    }
                    if (toTokenAddress === token0) {
                      let data0 = data[0] / Math.pow(10, toTokenDecimal)
                      let data1 = data[1] / Math.pow(10, fromTokenDecimal)

                      let test = data1 / data0
                      test = test * toTokenValue
                      test = test.toFixed(6)
                      setFromTokenValue(test)

                    }

                  })
                })



              })
            }

            // if (pair_address != "0x0000000000000000000000000000000000000000") {
            //   to_amount = toTokenValue;
            //   to_amount = amountConvert(to_amount, toTokenDecimal);
            //   if (to_amount >= 1) {
            //     if (
            //       fromTokenAddress != undefined &&
            //       toTokenAddress != undefined
            //     ) {
            //       Contract_Router.methods
            //         .quote(to_amount, toTokenAddress, fromTokenAddress)
            //         .call()
            //         .then((result) => {
            //           var Per_A = result;
            //           var a = Per_A / Math.pow(10, fromTokenDecimal);
            //           amount = a.toFixed(6);
            //           setFromTokenValue(amount);
            //         })
            //         .catch((err) => {
            //           setMessage("Something Went Wrong");
            //           toggle8();
            //           setFromTokenValue("")
            //           setToTokenValue("")
            //         })
            //     }
            //   } else {
            //     setFromTokenValue("");
            //   }
            // }
          });
      }
    }
    else {

    }
  };

  const refreshFrom = () => {
    setFromTokenValue("");
  };
  const refreshTo = () => {
    setToTokenValue("");
  };


  const transHistory = (
    hash,
    address,
    type,
    fromToken,
    toToken,
    fromTokenAddress,
    toTokenAddress,
    fromAmount,
    toAmount
  ) => {
    // var fromAmount = fromAmount / Math.pow(10, fromTokenDecimal);
    // var toAmount = toAmount / Math.pow(10, toTokenDecimal);
    Contract_Factory.methods
      .getPair(fromTokenAddress, toTokenAddress)
      .call()
      .then((pair_address) => {
        const pairInfo = "https://etherscan.io/address/" + pair_address;

        var contract_bal = new web3.eth.Contract(PairAddress_ABI, pair_address);
        contract_bal.methods
          .balanceOf(address)
          .call()
          .then((tokenbal) => {
            contract_bal.methods
              .decimals()
              .call()
              .then((decimal) => {
                var tokenbal_ori = tokenbal / Math.pow(10, decimal);
                var lp_value = tokenbal_ori.toFixed(6);

                const request = {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    user_address: address,
                    from_currency: fromToken,
                    to_currency: toToken,
                    from_address: fromTokenAddress,
                    to_address: toTokenAddress,
                    from_amount: fromAmount,
                    to_amount: toAmount,
                    tx_id: hash,
                    pair_address: pair_address,
                    pairInfo: pairInfo,
                    type: type,
                    lp_value: lp_value,
                    from_img: fromTokenImg,
                    to_img: toTokenImg
                  }),
                };
                fetch(

                  "https://api.collarswap.io/api/liquidity/addliquidity",
                  request
                ).then((data) => {
                  setLoad(false);
                  toast.success("Transaction Success")
                  // setMessage("Transaction Success");
                  // toggle8();
                  setFromTokenValue("");
                  setToTokenValue("");
                  history.push("/");

                });
              });
          });
      });

    // if (type == "add") {
    //   toast.success("Transaction Success")

    //   // setMessage("Transaction Success");
    //   // toggle8();
    //   SetTrigger(!triggerCall);
    // } else if (type == "remove") {
    //   toast.success("Transaction Success")

    //   // setMessage("Transaction Success ");
    //   // toggle8();
    //   SetTrigger(!triggerCall);
    //   setTimeout(() => {
    //     history.push("/");
    //   }, 1000);
    // }
  };

  const liquidityDataFn = (data) => {

    var params = [];
    setLoad(true)
    data.forEach((item, i) => {
      let contract = new web3.eth.Contract(PairAddress_ABI, item.pair_address);

      contract.methods
        .balanceOf(address)
        .call()
        .then((result) => {
          contract.methods
            .decimals()
            .call()
            .then((decimal) => {
              var tokenbal_ori = result / Math.pow(10, decimal);
              var lp_value = tokenbal_ori.toFixed(7);

              params[i] = {};
              params[i].tokenBal = lp_value;
              params[i].from_currency = item.from_currency;
              params[i].to_currency = item.to_currency;
              params[i].from_amount = item.from_amount;
              params[i].to_amount = item.to_amount;
              params[i].from_address = item.from_address;
              params[i].to_address = item.to_address;
              params[i].pair_address = item.pair_address;
              params[i].decimal = decimal;
              params[i].from_img = item.from_img
              params[i].to_img = item.to_img


              contract.methods
                .getReserves()
                .call()
                .then((result1) => {
                  var field1 = result1[0] / Math.pow(10, decimal);
                  field1 = field1.toFixed(2);
                  var field2 = result1[1] / Math.pow(10, decimal);
                  field2 = field2.toFixed(2);
                  params[i].fromRmvValue = field1;
                  params[i].toRmvValue = field2;

                  contract.methods
                    .totalSupply()
                    .call()
                    .then((result2) => {
                      var field3 = result2 / Math.pow(10, decimal);
                      field3 = field3.toFixed(2);
                      params[i].totalSupply = field3;
                      // setLiquidityDataVal(params);
                      if (data.length - 1 == i) {
                        setLiquidityDataVal(params);
                        console.log(params)
                        setLoad(false)
                      }
                    });
                })
                .catch();
            });
        })
        .catch();
    });
    // setTimeout(()=>{
    //   setLiquidityDataVal(params);
    //   console.log(params)
    //   setLoad(false)

    // },1000)
  };
  const testing = (data, i) => {
    setFromSym(data.from_currency);
    setToSym(data.to_currency);
    setFromRmvAddress(data.from_address);
    setToRmvAddress(data.to_address);
    setLpRmvAddress(data.pair_address);

    //
    setFromRmvValue(data.from_amount);
    setToRmvValue(data.to_amount);
    setDynamicFrmValue(data.from_amount);
    setDynamicToValue(data.to_amount);
    //

    var contract11 = new web3.eth.Contract(Approve_ABI, data.from_address);
    var contract12 = new web3.eth.Contract(Approve_ABI, data.to_address);
    contract11.methods
      .decimals()
      .call()
      .then((data) => {
        setFromDecimalToRemove(data);
      });

    contract12.methods
      .decimals()
      .call()
      .then((data) => {
        setToDecimalToRemove(data);
      });

    if (data.from_address != undefined && data.to_address != undefined) {
      Contract_Router.methods
        .quote("1000000000000000000", data.from_address, data.to_address)
        .call()
        .then((result) => {
          var a = result / Math.pow(10, data.decimal);
          let amount1 = a.toFixed(6);

          setRmvSecondQuote(amount1);
        });
      //
      Contract_Router.methods
        .quote("1000000000000000000", data.to_address, data.from_address)
        .call()
        .then((result) => {
          var a = result / Math.pow(10, data.decimal);
          let amount1 = a.toFixed(6);
          setRmvFirstQuote(amount1);
        });
    }
    let lpAddress = data.pair_address;
    let contract = new web3.eth.Contract(PairAddress_ABI, lpAddress);

    setTokenBal(data.tokenBal);
    setDynamicTokenValue(data.tokenBal);
  };

  useEffect(() => {
    if (fromTokenAddress !== undefined && toTokenAddress !== undefined) {

      LiquidityPerToken();
    }

  }, [fromTokenAddress, toTokenAddress])

  if (fromTokenName == toTokenName) {
    setToTokenName("choose")

    setFromTokenValue("")
    setToTokenValue("")
    setToTokenImg()
    setToBalance(0)
  }

  const maxClick = (addr, name, type) => {

    if (type == "from") {
      if (name == "ETH") {
        web3.eth.getBalance(address).then((balc) => {
          var bal = balc / 1000000000000000000
          bal = bal.toFixed(6)
          console.log(bal)
          setFromTokenValue(bal)
          if (fromTokenAddress !== undefined && toTokenAddress !== undefined && fromTokenAddress !== null && toTokenAddress !== null && fromTokenAddress !== "" && toTokenAddress !== "") {
            Contract_Factory.methods
              .getPair(fromTokenAddress, toTokenAddress)
              .call()
              .then((pair_address) => {
                if (pair_address == "0x0000000000000000000000000000000000000000") {
                  // setMessage("Add pair for first Time");
                  // toggle8();
                  // setFromTokenValue();
                  // setToTokenValue();
                  setShareOfPool("0");
                  setAtokenperBtoken("0");
                  setBtokenperAtoken("0");
                }
                else {
                  var Contract_Pair = new web3.eth.Contract(PairAddress_ABI, pair_address)

                  Contract_Pair.methods.getReserves().call().then((data) => {
                    Contract_Pair.methods.token0().call().then((token0) => {
                      Contract_Pair.methods.token1().call().then((token1) => {



                        if (fromTokenAddress == token0) {
                          let data0 = data[0] / Math.pow(10, fromTokenDecimal)
                          let data1 = data[1] / Math.pow(10, toTokenDecimal)
                          let test = data1 / data0

                          test = test * bal
                          test = test.toFixed(6)
                          setToTokenValue(test)

                        }
                        if (toTokenAddress == token0) {
                          let data0 = data[0] / Math.pow(10, toTokenDecimal)
                          let data1 = data[1] / Math.pow(10, fromTokenDecimal)

                          let test = data0 / data1
                          test = test * bal
                          test = test.toFixed(6)

                          setToTokenValue(test)

                        }

                      })
                    })



                  })
                }




              });
          }



        })

      }
      else {
        var contract = new web3.eth.Contract(Approve_ABI, addr)
        contract.methods.balanceOf(address).call().then((bal) => {
          var bal = bal / 1000000000000000000
          bal = bal.toFixed(6)

          setFromTokenValue(bal)
          if (fromTokenAddress !== undefined && toTokenAddress !== undefined && fromTokenAddress !== null && toTokenAddress !== null && fromTokenAddress !== "" && toTokenAddress !== "") {
            Contract_Factory.methods
              .getPair(fromTokenAddress, toTokenAddress)
              .call()
              .then((pair_address) => {
                if (pair_address == "0x0000000000000000000000000000000000000000") {
                  // setMessage("Add pair for first Time");
                  // toggle8();
                  // setFromTokenValue();
                  // setToTokenValue();
                  setShareOfPool("0");
                  setAtokenperBtoken("0");
                  setBtokenperAtoken("0");
                }
                else {
                  var Contract_Pair = new web3.eth.Contract(PairAddress_ABI, pair_address)

                  Contract_Pair.methods.getReserves().call().then((data) => {
                    Contract_Pair.methods.token0().call().then((token0) => {
                      Contract_Pair.methods.token1().call().then((token1) => {



                        if (fromTokenAddress == token0) {
                          let data0 = data[0] / Math.pow(10, fromTokenDecimal)
                          let data1 = data[1] / Math.pow(10, toTokenDecimal)
                          let test = data1 / data0
                          test = test * bal
                          test = test.toFixed(6)
                          setToTokenValue(test)

                        }
                        if (toTokenAddress == token0) {
                          let data0 = data[0] / Math.pow(10, toTokenDecimal)
                          let data1 = data[1] / Math.pow(10, fromTokenDecimal)
                          let test = data0 / data1
                          test = test * bal
                          test = test.toFixed(6)
                          setToTokenValue(test)

                        }

                      })
                    })



                  })
                }




              });
          }

        })

      }
    }
    else {

      if (name == "ETH") {
        web3.eth.getBalance(address).then((balc) => {
          var bal = balc / 1000000000000000000
          bal = bal.toFixed(6)
          setToTokenValue(bal)
          //
          if (fromTokenAddress !== undefined && toTokenAddress !== undefined && fromTokenAddress !== null && toTokenAddress !== null && fromTokenAddress !== "" && toTokenAddress !== "") {
            Contract_Factory.methods
              .getPair(fromTokenAddress, toTokenAddress)
              .call()
              .then((pair_address) => {
                if (pair_address == "0x0000000000000000000000000000000000000000") {

                  setShareOfPool("0");
                  setAtokenperBtoken("0");
                  setBtokenperAtoken("0");
                }
                else {
                  var Contract_Pair = new web3.eth.Contract(PairAddress_ABI, pair_address)
                  Contract_Pair.methods.getReserves().call().then((data) => {
                    Contract_Pair.methods.token0().call().then((token0) => {
                      Contract_Pair.methods.token1().call().then((token1) => {

                        if (fromTokenAddress === token0) {
                          let data0 = data[0] / Math.pow(10, fromTokenDecimal)
                          let data1 = data[1] / Math.pow(10, toTokenDecimal)

                          let test = data0 / data1
                          test = test * bal
                          test = test.toFixed(6)
                          setFromTokenValue(test)



                        }
                        if (toTokenAddress === token0) {
                          let data0 = data[0] / Math.pow(10, toTokenDecimal)
                          let data1 = data[1] / Math.pow(10, fromTokenDecimal)

                          let test = data1 / data0
                          test = test * bal
                          test = test.toFixed(6)
                          setFromTokenValue(test)

                        }

                      })
                    })



                  })
                }


              });
          }
          //
        })

      }
      else {
        if (addr !== undefined && addr !== "" && addr !== null) {
          console.log(addr)
          var contract = new web3.eth.Contract(Approve_ABI, addr)
          contract.methods.balanceOf(address).call().then((bal) => {
            console.log(bal)

            var bal = bal / 1000000000000000000
            bal = bal.toFixed(6)
            setToTokenValue(bal)
            if (fromTokenAddress !== undefined && toTokenAddress !== undefined && fromTokenAddress !== null && toTokenAddress !== null && fromTokenAddress !== "" && toTokenAddress !== "") {
              Contract_Factory.methods
                .getPair(fromTokenAddress, toTokenAddress)
                .call()
                .then((pair_address) => {
                  if (pair_address == "0x0000000000000000000000000000000000000000") {

                    setShareOfPool("0");
                    setAtokenperBtoken("0");
                    setBtokenperAtoken("0");
                  }
                  else {
                    var Contract_Pair = new web3.eth.Contract(PairAddress_ABI, pair_address)
                    Contract_Pair.methods.getReserves().call().then((data) => {
                      Contract_Pair.methods.token0().call().then((token0) => {
                        Contract_Pair.methods.token1().call().then((token1) => {

                          if (fromTokenAddress === token0) {
                            let data0 = data[0] / Math.pow(10, fromTokenDecimal)
                            let data1 = data[1] / Math.pow(10, toTokenDecimal)
                            let test = data0 / data1
                            test = test * bal
                            test = test.toFixed(6)
                            if (toBalance < test) {
                              setFromTokenValue(test)
                            }
                            else {
                              setFromTokenValue(test)
                            }

                          }
                          if (toTokenAddress === token0) {
                            let data0 = data[0] / Math.pow(10, toTokenDecimal)
                            let data1 = data[1] / Math.pow(10, fromTokenDecimal)
                            let test = data1 / data0
                            test = test * bal
                            test = test.toFixed(6)
                            if (fromBalance < test) {
                              setFromTokenValue(test)
                            }
                            else {
                              setFromTokenValue(test)
                            }

                          }

                        })
                      })



                    })
                  }


                });
            }
          })
        }
        else {
          toast.info("Please Choose Any Token")
        }

      }
    }
  }

  const addTokenToWallet = async (tokenDetails) => {
    let tokenData = {
      address: tokenDetails.pair_address, // The address that the token is at.
      symbol: tokenDetails.from_currency + "_" + tokenDetails.to_currency, // A ticker symbol or shorthand, up to 5 chars.
      decimals: 18, // The number of decimals in the token
      image: tokenDetails.tokenImg, // A string url of the token logo
    };
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      window.ethereum
        .request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20", // Initially only supports ERC20, but eventually more!
            options: tokenData,
          },
        })
        .then((resp) => {
          toast.success("Token Added Successfully!");
        });
    } catch (error) {
      toast.error("Something went wrong.Please try again later.");
    }
  };

  return (
    <Fragment>
      <LoadingOverlay
        active={load}
        spinner
        text={loadMsg}
        styles={{
          content: (base) => ({
            ...base,
            color: "#FEA501",
          }),
          spinner: (base) => ({
            ...base,
            margin: "240px auto 10px auto",
            width: "100px",
            "& svg circle": {
              stroke: "#FEA501",
            },
          }),
          overlay: (base) => ({
            ...base,
            background: "rgb(0, 0, 0, 0.7)",
          }),
        }}
      >
        <div className="cnt-load-div">
          <div className="container-fluid p-0">
            <div className="basic-layout">
              <div className="container">
                <div className="main-content-load-div">
                  <Sidebar openSidebar={sidebarStatus} />
                  <div className="main-content">
                    <Header toggleSidebar={setSidebarStatus} sidebarStatus={sidebarStatus} />
                    <div className="swap-page">
                      <div className="row mt-5 mb-4 mx-0">
                        <div className="col-lg-12">
                          <div className="swap-title-div p-3 row">
                            <img
                              src={newdoge2}
                              className="dog d-block img-fluid col-md-4"
                            />
                            <div className="mdiv col-md-4">
                              <h3>Add Liquidity</h3>
                              <p>Add liquidity to receive LP tokens</p>
                            </div>
                            <img
                              src={newdoge1}
                              className="dog d-block img-fluid  col-md-4"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-lg-12">
                          <div className="swap-liq-div">
                            <div className="refresh-settings-div">
                              <img
                                src={refreshImg}
                                className="cur-point"
                                onClick={toggle3}
                              />
                              <img
                                src={settingsImg}
                                className="ml-2 cur-point"
                                onClick={toggle2}
                              />
                            </div>
                            <Nav tabs>
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeTab === "1",
                                  })}
                                  onClick={() => {
                                    toggle("1");
                                  }}
                                >
                                  Liquidity
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                {/* <NavLink
                                  className={classnames({
                                    active: activeTab === "2",
                                  })}
                                  onClick={() => {
                                    toggle("2");
                                  }}
                                >
                                  <Link
                                    className="text-light text-decoration-none"
                                    to="/swap"
                                  >
                                    Swap
                                  </Link>
                                </NavLink> */}
                              </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                              <TabPane tabId="1">
                                {!liquidityOpen ? (
                                  <div
                                    className={`row align-items-center mb-5 ${display ? "d-none" : null
                                      } `}
                                  >
                                    <div
                                      className="col-lg-12"
                                      isOpen={!liquidityOpen}
                                    >
                                      <div className="swap-liq-det-div p-3 text-center">
                                        <h4>Your Liquidity</h4>
                                        <p>
                                          First add liquidity to receive LP tokens for Staking
                                        </p>
                                        <p>
                                          Removing Liqudidity possible only if Liquidity greater than 0.00001 LP
                                        </p>

                                        <button
                                          className="btn con-wal-btn"
                                          onClick={toggle_hide}
                                        >
                                          ADD LIQUIDITY
                                        </button>

                                        <div className="d-flex remove-liquidity-div">
                                          <div className="swap-liq-det-div mb-3 p-3 w-100">
                                            <div className="token-det-div mb-3">
                                              <div
                                                className="d-flex align-items-center"
                                                onClick={togglecol}
                                              >
                                                <h3 className="mb-0">
                                                  LP tokens in your wallet
                                                </h3>
                                                <img
                                                  src={downarrowImg}
                                                  className="ml-auto"
                                                />
                                              </div>

                                              {liquidityDataVal && liquidityDataVal.map(
                                                (data, i) => {
                                                  return (
                                                    <Collapse isOpen={isOpen}>
                                                      {data.tokenBal > 0.000000001 ? (
                                                        <>
                                                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-2">
                                                            <span className="val">
                                                              <img
                                                                src={data.from_img ? data.from_img : bnbImg}
                                                              />
                                                              <img
                                                                src={data.to_img ? data.to_img : dclogosmallImg}
                                                                className="mx-2"
                                                              />
                                                              {
                                                                data.from_currency
                                                              }
                                                              /
                                                              {data.to_currency}
                                                            </span>
                                                            <span className="val">
                                                              {data.tokenBal}
                                                            </span>
                                                          </div>
                                                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-2" onClick={() => addTokenToWallet(data)} >
                                                            <span className="val">
                                                              <img className="mr-3" style={{ width: "20px" }} src={metamask} />Add To Wallet                                                            </span>
                                                          </div>

                                                          <div className="d-flex justify-content-center">
                                                            {data.tokenBal > 0.000000001}
                                                            <button
                                                              className="btn con-wal-btn"
                                                              disabled={data.tokenBal < 0.00001}
                                                              onClick={(i) => {
                                                                displayClick();
                                                                testing(
                                                                  data,
                                                                  i
                                                                );
                                                              }}
                                                            >
                                                              Remove Liquidity
                                                            </button>
                                                          </div>
                                                        </>
                                                      ) : null}
                                                    </Collapse>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="d-flex justify-content-center flex-wrap mb-3">
                                          <span>
                                            Learn about providing liquidity?
                                          </span>
                                          <Link to="/News" className="ml-3">
                                            Click Here.
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                {liquidityOpen ? (
                                  <>
                                    <div className="row align-items-center mb-4  ">
                                      <div className="col-lg-5">
                                        <div className="swap-liq-det-div p-3">
                                          <div className="coin-exchange-div mb-3">
                                            <div
                                              className="d-flex align-items-center cur-point"
                                              onClick={toggle1}
                                            >
                                              <img src={fromTokenImg} />
                                              <span className="mx-2">
                                                {fromTokenName}
                                              </span>
                                              <img src={downarrowImg} />
                                            </div>
                                          </div>
                                          <label>Enter Your Amount  <span onClick={() => { maxClick(fromTokenAddress, fromTokenName, "from") }} style={{ color: "#FF110B", width: "80px", cursor: "pointer" }}>(Max)</span></label>                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="0.0000"
                                            value={fromTokenValue}
                                            onKeyUp={fromLiquidityData}
                                            onChange={(e) => {
                                              const amount = e.target.value;
                                              var regexp = new RegExp(
                                                "^[0-9]*[.]?[0-9]*$"
                                              );
                                              if (regexp.test(amount)) {
                                                setFromTokenValue(amount);
                                              }
                                            }}
                                          />
                                          <div>
                                            <label className="mx-1">
                                              Balance : {fromBalance}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="col-lg-2"

                                      >
                                        <div className="p-3">
                                          <img
                                            src={plusIcon}
                                            className="d-block m-auto img-fluid"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-5">
                                        <div className="swap-liq-det-div p-3">
                                          <div className="coin-exchange-div mb-3">
                                            <div
                                              className="d-flex align-items-center cur-point"
                                              onClick={toggle4}
                                            >
                                              <img src={toTokenImg} />
                                              <span className="mx-2">
                                                {toTokenName}
                                              </span>
                                              <img src={downarrowImg} />
                                            </div>
                                          </div>
                                          <label>Enter Your Amount  <span onClick={() => { maxClick(toTokenAddress, toTokenName, "to") }} style={{ color: "#FF110B", width: "80px", cursor: "pointer" }}>(Max)</span></label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="0.0000"
                                            value={toTokenValue}
                                            onKeyUp={toLiquidityData}
                                            onChange={(e) => {
                                              const amount = e.target.value;

                                              var regexp = new RegExp(
                                                "^[0-9]*[.]?[0-9]*$"
                                              );
                                              if (regexp.test(amount)) {
                                                setToTokenValue(amount);
                                              }
                                            }}
                                          />
                                          <div>
                                            <label className="mx-1">
                                              Balance : {toBalance}
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row justify-content-center align-items-center">
                                      <div className="col-lg-8">
                                        <div className="swap-liq-det-div p-3 text-center">
                                          <h4 className="text-center">
                                            Prices and pool share
                                          </h4>
                                          <div className="value-div">
                                            <div className="ldiv">
                                              <span className="val">
                                                {btokenperAtoken}
                                              </span>
                                              <span>
                                                {fromTokenName} per{" "}
                                                {toTokenName}
                                              </span>
                                            </div>
                                            <div className="ldiv mx-2">
                                              <span className="val">
                                                {atokenperBtoken}
                                              </span>
                                              <span>
                                                {toTokenName} per{" "}
                                                {fromTokenName}
                                              </span>
                                            </div>
                                            <div className="ldiv">
                                              <span className="val">
                                                {shareOfPool}%
                                              </span>
                                              <span>Share of Pool</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* {fromTokenName == toTokenName ? (
                                      <button className="btn con-wal-btn">
                                        Invalid Pair
                                      </button>
                                    ) : fromTokenName !== toTokenName &&
                                      toTokenAddress ? (
                                      <button
                                        className="btn con-wal-btn"
                                        onClick={addLiquidity}
                                        disabled={
                                          toTokenValue > 0 &&
                                            fromTokenValue > 0 &&
                                            fromTokenName !== toTokenName &&
                                            toTokenAddress
                                            ? false
                                            : true
                                        }
                                      >
                                        Add Liquidity
                                      </button>
                                    ) : (
                                      <button
                                        className="btn con-wal-btn"
                                        onClick={() => {
                                          setMessage("Please select Token");
                                          toggle8();
                                        }}
                                      >

                                        Add Liquidity
                                      </button>
                                    )} */}

                                    {address != "" && address != undefined ? <button
                                      className="btn con-wal-btn"
                                      onClick={addLiquidity}
                                      disabled={
                                        toTokenValue > 0 &&
                                          fromTokenValue > 0 &&
                                          fromTokenName !== toTokenName &&
                                          toTokenAddress
                                          ? false
                                          : true
                                      }
                                    >
                                      Add Liquidity
                                    </button> : <button
                                      className="btn con-wal-btn"
                                      onClick={addLiquidity}

                                    >
                                      Connect Metamask
                                    </button>}
                                  </>
                                ) : null}

                                <div
                                  className={`remove-liquidity-div ${!display ? "d-none" : null
                                    }`}
                                >
                                  <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                      <div className="swap-liq-det-div p-3 mb-3">
                                        <div className="amt-det mb-3">
                                          <span>Amount</span>
                                          <span>Detailed</span>
                                        </div>
                                        <div class="remove-progress-div mb-4">
                                          <span className="pro-percent">
                                            {range}%
                                          </span>
                                          <ProgressBar
                                            className="mb-3"
                                            percent={range}
                                          >
                                            <Step transition="scale">
                                              {({ accomplished }) => (
                                                <div className="zero-start"></div>
                                              )}
                                            </Step>
                                            <Step transition="scale">
                                              {({ accomplished }) => (
                                                <div className="zero-start pro-start"></div>
                                              )}
                                            </Step>
                                            <Step transition="scale">
                                              {({ accomplished }) => (
                                                <div className="zero-start pro-start"></div>
                                              )}
                                            </Step>
                                          </ProgressBar>
                                        </div>
                                        <div className="range-div mb-3">
                                          <div
                                            className="val"
                                            onClick={() => {
                                              rangeSet(25);
                                            }}
                                          >
                                            <span>25%</span>
                                          </div>
                                          <div
                                            className="val active"
                                            onClick={() => {
                                              rangeSet(50);
                                            }}
                                          >
                                            <span>50%</span>
                                          </div>
                                          <div
                                            className="val"
                                            onClick={() => {
                                              rangeSet(75);
                                            }}
                                          >
                                            <span>75%</span>
                                          </div>
                                          <div
                                            className="val"
                                            onClick={() => rangeSet(100)}
                                          >
                                            <span>MAX</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                      <div className="swap-liq-det-div mb-3">
                                        <div className="token-val-div p-4">
                                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-2">
                                            <span className="val">Estimated Gas</span>
                                            <span>{gas}</span>
                                          </div>

                                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-2">
                                            <span className="val">
                                              Pooled Joint
                                            </span>
                                            <span>
                                              {dynamicTokenValue}/{tokenBal}
                                            </span>
                                          </div>
                                          <div className="d-flex justify-content-between align-items-center flex-wrap">
                                            <span className="val">
                                              Pooled {fromSym}
                                            </span>
                                            <span>
                                              {dynamicFrmValue} / {fromRmvValue}
                                            </span>
                                          </div>
                                          <div className="d-flex justify-content-between align-items-center flex-wrap">
                                            <span className="val">
                                              Pooled {toSym}
                                            </span>
                                            <span>
                                              {dynamicToValue}/ {toRmvValue}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                      <div className="swap-liq-det-div mb-3 p-3">
                                        {/* <div className="price-div">
                                          <span className="val">Price</span>
                                          <div className="rdiv">
                                            <div className="value mb-2">
                                              <span>1 {toSym}</span>
                                              <span className="mx-2">=</span>
                                              <span>
                                                {rmvSecondQuote} {fromSym}
                                              </span>
                                            </div>
                                            <div className="value mb-2">
                                              <span> 1 {fromSym}</span>
                                              <span className="mx-2">=</span>
                                              <span>
                                                {rmvFirstQuote} {toSym}
                                              </span>
                                            </div>
                                          </div>
                                        </div> */}
                                        <div className="btndiv">
                                          <Link to="/">
                                            {" "}
                                            <button className="btn approve-btn mr-3">
                                              Cancel
                                            </button>
                                          </Link>
                                          <button
                                            className="btn remove-btn"
                                            onClick={removeLiquidityRemove}
                                          >
                                            Remove
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </TabPane>
                            </TabContent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggle1} className="sel-coin-modal">
          <ModalHeader toggle={toggle1}>Select Token</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search name or Paste address"
                  onKeyUp={fromSearch}
                />


                {initialTokenList.map((data, i) => {
                  return (
                    <div
                      className="coin-list mb-2"
                      onClick={() => {
                        selectFrom(
                          data.tokenAddress,
                          data.tokenName,
                          data.tokenDecimal,
                          data.tokenImg
                        );
                        toggle1();
                        refreshFrom();
                        refreshTo();
                      }}
                    >
                      <div className="ldiv">
                        <img src={data.tokenImg} className="mr-2" />
                        <span>{data.tokenName}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={modal3} toggle={toggle4} className="sel-coin-modal">
          <ModalHeader toggle={toggle4}>Select Token</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search name or Paste address"
                  onKeyUp={toSearch}
                />

                {toInitialTokenList.map((data, i) => {
                  return (
                    <div
                      className="coin-list mb-2"

                      onClick={() => {
                        selectTo(
                          data.tokenAddress,
                          data.tokenName,
                          data.tokenDecimal,
                          data.tokenImg
                        );
                        toggle4();
                        refreshFrom();
                        refreshTo();
                      }}
                    >
                      <div className="ldiv">
                        <img src={data.tokenImg} className="mr-2" />
                        <span>{data.tokenName}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={modal1} toggle={toggle2} className="settings-modal">
          <ModalHeader toggle={toggle2}>Settings</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12">
                <h3>Default Transaction Speed (GWEI)</h3>
                <div className="speed-div">
                  {GWEI == 5000000000 ? <div
                    className="value"
                    style={{ background: "linear-gradient(0deg, #FEA501 -0.01%, #692705 100.03%)" }}

                    onMouseUp={() => {
                      setGWEI(5 * 1000000000);
                    }}
                  >
                    <span >Standard (5)</span>
                  </div> : <div
                    className="value"

                    onMouseUp={() => {
                      setGWEI(5 * 1000000000);
                    }}
                  >
                    <span >Standard (5)</span>
                  </div>}
                  {GWEI == 6000000000 ? <div
                    className="value"
                    style={{ background: "linear-gradient(0deg, #FEA501 -0.01%, #692705 100.03%)" }}

                    onMouseUp={() => {
                      setGWEI(6 * 1000000000);
                    }}
                  >
                    <span>Fast (6)</span>
                  </div> : <div
                    className="value"
                    onMouseUp={() => {
                      setGWEI(6 * 1000000000);
                    }}
                  >
                    <span>Fast (6)</span>
                  </div>}
                  {GWEI == 7000000000 ? <div
                    className="value"
                    style={{ background: "linear-gradient(0deg, #FEA501 -0.01%, #692705 100.03%)" }}

                    onMouseUp={() => {
                      setGWEI(7 * 1000000000);
                    }}
                  >
                    <span>Instant (7)</span>
                  </div> : <div
                    className="value"
                    onMouseUp={() => {
                      setGWEI(7 * 1000000000);
                    }}
                  >
                    <span>Instant (7)</span>
                  </div>}
                </div>
                <div className="mode-div">

                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h3 className="mb-0">SLIPPAGE TOLERANCE</h3>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Max 3"
                      maxlength="3"

                      onChange={(e) => {
                        setTolerance(e.target.value);
                      }}
                      value={tolerance}
                    />
                  </div>
                </div>
                {/* <div className="slippage-div">
                  <div
                    className="value"
                    onClick={() => {
                      setTolerance(0.1);
                      toggle2();
                    }}
                  >
                    <span>0.1%</span>
                  </div>
                  <div
                    className="value"
                    onClick={() => {
                      setTolerance(0.5);
                      toggle2();
                    }}
                  >
                    <span>0.5%</span>
                  </div>
                  <div
                    className="value"
                    onClick={() => {
                      setTolerance(1);
                      toggle2();
                    }}
                  >
                    <span>1.0%</span>
                  </div>
                  <div
                    className="value"
                    onClick={() => {
                      setTolerance(2);
                      toggle2();
                    }}
                  >
                    <span>2.0%</span>
                  </div>
                </div> */}
                <div className="mode-div">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <span>Tx deadline (mins)</span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="0"
                      onChange={(e) => {
                        setTxnDeadline(e.target.value);
                      }}
                      maxlength="3"

                      value={txnDeadline}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={modal2} toggle={toggle3} className="settings-modal">
          <ModalHeader toggle={toggle3}>Recent Transaction</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12">
                <div className="recent-trans-table">
                  <div className="table-responsive">
                    {historyData.map((data, i) => {
                      return (
                        <table className="table">
                          <tbody>
                            <tr className="w-100">
                              <td className="w-50">
                                {data.from_currency} - {data.to_currency}
                              </td>
                              <td>For</td>

                              <td className="address w-50">{data.lp_value}</td>
                            </tr>
                          </tbody>
                        </table>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={modal8} toggle={toggle8} className="comingsoon-poup">
          {/* <ModalHeader toggle={toggle8}>ALERT</ModalHeader> */}
          <ModalBody>
            {/* <div className="row">
              <div className="col-lg-12">
                <div className="recent-trans-table">
                  <div className="table-responsive">
                    <p className="d-flex justify-content-center text-light ">
                      {message}
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <Modal isOpen={modal10} toggle={toggle10} className="comingsoon-poup"> */}
            {/* <ModalHeader toggle={toggle10}>Alert</ModalHeader> */}
            {/* <ModalBody> */}
            <div class="text-center">
              {/* <h3>KOVEN TESTNET EARLY ACCESS</h3> */}
              <h5>{message}</h5>
            </div>
            {/* </ModalBody>
      </Modal> */}
          </ModalBody>
        </Modal>
      </LoadingOverlay>
    </Fragment>
  );
};

export default Swap;
