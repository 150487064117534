import React, {
  Component,
  Fragment,
  useState,
  useRef,
  useEffect,
  createRef,
} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse,
} from "reactstrap";
import axios from "axios";

import classnames from "classnames";
import "./css/swap.scss";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/Sidebar/Sidebar";

import dogswapleftImg from "../../assets/images/dogswapleft.png";
import dogswaprightImg from "../../assets/images/dogswapright.png";
import downarrowImg from "../../assets/images/downarrow.png";
import exchangeImg from "../../assets/images/exchange.png";
import bnbImg from "../../assets/images/bnb.png";
import btcImg from "../../assets/images/btc.png";
import refreshImg from "../../assets/images/refresh.png";
import settingsImg from "../../assets/images/settings.png";
import newdoge2 from "../../assets/images/newdoge2.png";
import newdoge1 from "../../assets/images/newdoge.png";



import dashImg from "../../assets/images/dash.png";
import xrmImg from "../../assets/images/xrm.png";
import usdtImg from "../../assets/images/usdt.png";
import actImg from "../../assets/images/act.png";
import cakeImg from "../../assets/images/cake.png";
import appcImg from "../../assets/images/appc.png";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "react-loading-overlay";


// ABI files//
import Approve_ABI from "../../ABI/Approve_ABI.json";
import Factory_ABI from "../../ABI/Factory_ABI.json";
import Router_ABI from "../../ABI/Router_ABI.json";
import PairAddress_ABI from "../../ABI/PairAddress_ABI.json";

// contract cons //
import {
  Router_Address,
  Factory_Address,

  Approve_Address,
  Weth_Address,
  Collar_Address,
  Gem_Address
} from "../../common/Contract_cons";

const Web3 = require("web3");
const web3 = new Web3(window.ethereum);

const Swap = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {

    if (activeTab !== tab) {

      setActiveTab(tab)
    };
  };

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);

  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);

  const [modal4, setModal4] = useState(false);
  const toggle4 = () => setModal4(!modal4);

  const [modal8, setModal8] = useState(false);
  const toggle8 = () => setModal8(!modal8);



  const [rotate, setRotate] = useState(false)
  const [modal55, setModal55] = useState(false);
  const toggle55 = () => setModal55(!modal55);

  const [fromTokenAddress, setFromTokenAddress] = useState(
    "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
  );
  const [toTokenAddress, setToTokenAddress] = useState("");
  const [fromTokenName, setFromTokenName] = useState("ETH");
  const [toTokenName, setToTokenName] = useState("select");
  const [fromTokenDecimal, setFromTokenDecimal] = useState(18)
  const [toTokenDecimal, setToTokenDecimal] = useState()

  const [fromTokenImg, setFromTokenImg] = useState()
  const [toTokenImg, setToTokenImg] = useState()

  const [fromTokenValue, setFromTokenValue] = useState();
  const [toTokenValue, setToTokenValue] = useState();


  const [fromBalance, setFromBalance] = useState(0);
  const [toBalance, setToBalance] = useState(0);


  const [GWEI, setGWEI] = useState(0);
  const [tolerance, setTolerance] = useState(0.1);
  const [txnDeadline, setTxnDeadline] = useState(2);


  const [initialTokenList, setInitialTokenList] = useState([])

  const [tokenData, setTokenData] = useState([])

  const [toInitialTokenList, setToInitialTokenList] = useState([])

  const [swapHistory, setSwapHistory] = useState([])
  const [atokenperBtoken, setAtokenperBtoken] = useState(0);
  const [btokenperAtoken, setBtokenperAtoken] = useState(0);

  const [shareOfPool, setShareOfPool] = useState(0);


  const [load, setLoad] = useState(false)
  const loading = () => setLoad(!load)

  const [message, setMessage] = useState("Loading")
  const [alert, setAlert] = useState("")

  const [triggerCall, SetTrigger] = useState(true);

  const [sidebarStatus, setSidebarStatus] = useState(false)


  useEffect(() => {
    let address = JSON.parse(window.localStorage.getItem("metaAddress"));

    axios.get("https://api.collarswap.io/api/swap/listswapdata")
      .then((res) => {
        let data = res.data.result
        let data1 = data.reverse()
        setSwapHistory(data1)
      })


    axios.get("https://api.collarswap.io/api/currency/listcurrency")
      .then((res) => {
        setInitialTokenList(res.data.result)
        setTokenData(res.data.result)

        var arr = []

        for (let i = 0; i < res.data.result.length; i++) {
          if (res.data.result[i].tokenName !== "ETH") {
            arr.push(res.data.result[i])

          }
        }

        setToInitialTokenList(arr)
      })



    if (address != "" && address != undefined) {
      web3.eth.getBalance(address).then((balc) => {
        let a = (balc / Math.pow(10, 18)).toFixed(2);
        setFromBalance(a);

      });


    }


  }, [address, triggerCall]);


  var from_amount;
  var to_amount;
  var gas_Price;

  var address = JSON.parse(window.localStorage.getItem("metaAddress"));

  var Contract_Factory = new web3.eth.Contract(Factory_ABI, Factory_Address);

  var Contract_Router = new web3.eth.Contract(Router_ABI, Router_Address);


  const balanceChange = () => {
    var contract = new web3.eth.Contract(Approve_ABI, Collar_Address);
    contract.methods
      .balanceOf(address)
      .call()
      .then((tokenbal) => {
        var tokenbal_ori = tokenbal / Math.pow(10, 18);
        var newval = tokenbal_ori.toFixed(4);
        localStorage.setItem("Cbalance", JSON.stringify(newval));
      })
    var contract1 = new web3.eth.Contract(Approve_ABI, Gem_Address)
    // contract1.methods.balanceOf(address).call()
    //   .then((tokenbal) => {
    //     var tokenbal_ori = tokenbal / Math.pow(10, 18);
    //     var newval = tokenbal_ori.toFixed(4);
    //     localStorage.setItem("Ebalance", JSON.stringify(newval));

    //   })

  }

  const amountConvert = (amount, decimal, type = "towei") => {
    if (type == "towei") {
      let coinwei = Math.pow(10, decimal);
      let sendAmount = amount * coinwei;
      return (sendAmount = getNumber(sendAmount));
    } else if (type == "fromwei") {
      let coinwei = Math.pow(10, decimal);
      let sendAmount = amount / coinwei;
      return (sendAmount = getNumber(sendAmount));
    }
  };

  const getNumber = (x) => {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }
    if (!Number.isInteger(x)) {
      x = x.toString();
    }

    return x.toString();
  };

  const maxClick = (addr, name, type) => {
    // if (addr, name, type) {
    if (type == "from") {
      if (name == "ETH") {
        web3.eth.getBalance(address).then((balc) => {
          var bal = balc / 1000000000000000000
          bal = bal.toFixed(2)
          console.log(bal)
          setFromTokenValue(bal)
          if (fromTokenAddress !== undefined && toTokenAddress !== undefined && fromTokenAddress !== null && toTokenAddress !== null && fromTokenAddress !== "" && toTokenAddress !== "") {
            Contract_Factory.methods.getPair(fromTokenAddress, toTokenAddress)
              .call()
              .then((pair_address) => {
                if (pair_address == "0x0000000000000000000000000000000000000000") {
                  // setMessage("Add pair for first Time");
                  // toggle8();
                  // setFromTokenValue();
                  // setToTokenValue();
                  setShareOfPool("0");
                  setAtokenperBtoken("0");
                  setBtokenperAtoken("0");
                }
                else {
                  var Contract_Pair = new web3.eth.Contract(PairAddress_ABI, pair_address)

                  Contract_Pair.methods.getReserves().call().then((data) => {
                    Contract_Pair.methods.token0().call().then((token0) => {
                      Contract_Pair.methods.token1().call().then((token1) => {



                        if (fromTokenAddress == token0) {
                          let data0 = data[0] / Math.pow(10, fromTokenDecimal)
                          let data1 = data[1] / Math.pow(10, toTokenDecimal)
                          let test = data1 / data0

                          test = test * bal
                          test = test.toFixed(2)
                          setToTokenValue(test)

                        }
                        if (toTokenAddress == token0) {
                          let data0 = data[0] / Math.pow(10, toTokenDecimal)
                          let data1 = data[1] / Math.pow(10, fromTokenDecimal)

                          let test = data0 / data1
                          test = test * bal
                          test = test.toFixed(2)

                          setToTokenValue(test)

                        }

                      })
                    })



                  })
                }




              });
          }




        })

      }
      else {
        var contract = new web3.eth.Contract(Approve_ABI, addr)
        contract.methods.balanceOf(address).call().then((bal) => {
          var bal = bal / 1000000000000000000
          bal = bal.toFixed(2)

          setFromTokenValue(bal)
          if (fromTokenAddress !== undefined && toTokenAddress !== undefined && fromTokenAddress !== null && toTokenAddress !== null && fromTokenAddress !== "" && toTokenAddress !== "") {
            Contract_Factory.methods
              .getPair(fromTokenAddress, toTokenAddress)
              .call()
              .then((pair_address) => {
                if (pair_address == "0x0000000000000000000000000000000000000000") {
                  // setMessage("Add pair for first Time");
                  // toggle8();
                  // setFromTokenValue();
                  // setToTokenValue();
                  setShareOfPool("0");
                  setAtokenperBtoken("0");
                  setBtokenperAtoken("0");
                }
                else {
                  var Contract_Pair = new web3.eth.Contract(PairAddress_ABI, pair_address)

                  Contract_Pair.methods.getReserves().call().then((data) => {
                    Contract_Pair.methods.token0().call().then((token0) => {
                      Contract_Pair.methods.token1().call().then((token1) => {



                        if (fromTokenAddress == token0) {
                          let data0 = data[0] / Math.pow(10, fromTokenDecimal)
                          let data1 = data[1] / Math.pow(10, toTokenDecimal)
                          let test = data1 / data0

                          test = test * bal
                          test = test.toFixed(2)
                          setToTokenValue(test)

                        }
                        if (toTokenAddress == token0) {
                          let data0 = data[0] / Math.pow(10, toTokenDecimal)
                          let data1 = data[1] / Math.pow(10, fromTokenDecimal)

                          let test = data0 / data1
                          test = test * bal
                          test = test.toFixed(2)

                          setToTokenValue(test)

                        }

                      })
                    })



                  })
                }




              });
          }

        })

      }
    }
    else {

      if (name == "ETH") {
        web3.eth.getBalance(address).then((balc) => {
          var bal = balc / 1000000000000000000
          bal = bal.toFixed(6)
          setToTokenValue(bal)
          //
          if (fromTokenAddress !== undefined && toTokenAddress !== undefined && fromTokenAddress !== null && toTokenAddress !== null && fromTokenAddress !== "" && toTokenAddress !== "") {
            Contract_Factory.methods
              .getPair(fromTokenAddress, toTokenAddress)
              .call()
              .then((pair_address) => {
                if (pair_address == "0x0000000000000000000000000000000000000000") {

                  setShareOfPool("0");
                  setAtokenperBtoken("0");
                  setBtokenperAtoken("0");
                }
                else {
                  var Contract_Pair = new web3.eth.Contract(PairAddress_ABI, pair_address)
                  Contract_Pair.methods.getReserves().call().then((data) => {
                    Contract_Pair.methods.token0().call().then((token0) => {
                      Contract_Pair.methods.token1().call().then((token1) => {

                        if (fromTokenAddress === token0) {
                          let data0 = data[0] / Math.pow(10, fromTokenDecimal)
                          let data1 = data[1] / Math.pow(10, toTokenDecimal)

                          let test = data0 / data1
                          test = test * bal
                          test = test.toFixed(6)
                          setFromTokenValue(test)



                        }
                        if (toTokenAddress === token0) {
                          let data0 = data[0] / Math.pow(10, toTokenDecimal)
                          let data1 = data[1] / Math.pow(10, fromTokenDecimal)

                          let test = data1 / data0
                          test = test * bal
                          test = test.toFixed(6)
                          setFromTokenValue(test)

                        }

                      })
                    })



                  })
                }


              });
          }
          //
        })

      }
      else {
        if (addr !== undefined && addr !== null && addr !== "") {
          console.log(addr)
          var contract = new web3.eth.Contract(Approve_ABI, addr)
          contract.methods.balanceOf(address).call().then((bal) => {
            console.log(bal)

            var bal = bal / 1000000000000000000
            bal = bal.toFixed(6)
            setToTokenValue(bal)
            if (fromTokenAddress !== undefined && toTokenAddress !== undefined && fromTokenAddress !== null && toTokenAddress !== null && fromTokenAddress !== "" && toTokenAddress !== "") {
              Contract_Factory.methods
                .getPair(fromTokenAddress, toTokenAddress)
                .call()
                .then((pair_address) => {
                  if (pair_address == "0x0000000000000000000000000000000000000000") {

                    setShareOfPool("0");
                    setAtokenperBtoken("0");
                    setBtokenperAtoken("0");
                  }
                  else {
                    var Contract_Pair = new web3.eth.Contract(PairAddress_ABI, pair_address)
                    Contract_Pair.methods.getReserves().call().then((data) => {
                      Contract_Pair.methods.token0().call().then((token0) => {
                        Contract_Pair.methods.token1().call().then((token1) => {

                          if (fromTokenAddress === token0) {
                            let data0 = data[0] / Math.pow(10, fromTokenDecimal)
                            let data1 = data[1] / Math.pow(10, toTokenDecimal)
                            let test = data0 / data1
                            test = test * bal
                            test = test.toFixed(6)
                            setFromTokenValue(test)
                          }
                          if (toTokenAddress === token0) {
                            let data0 = data[0] / Math.pow(10, toTokenDecimal)
                            let data1 = data[1] / Math.pow(10, fromTokenDecimal)

                            let test = data1 / data0
                            test = test * bal
                            test = test.toFixed(6)
                            setFromTokenValue(test)

                          }

                        })
                      })



                    })
                  }


                });
            }
          })
        }
        else {
          toast.info("Please Choose Any Token")
        }

      }
    }
    // }
    // else {
    //   toast.error("Set a Equivalent Token")
    // }

  }
  const getValueOut = async (e) => {
    if (fromTokenAddress !== undefined && toTokenAddress !== undefined) {
      Contract_Factory.methods
        .getPair(fromTokenAddress, toTokenAddress)
        .call()
        .then((pair_address) => {
          if (pair_address == "0x0000000000000000000000000000000000000000") {
            // setAlert("Invalid Pair")
            // toggle55();
            setFromTokenValue();
            setToTokenValue();
          }
          if (pair_address != "0x0000000000000000000000000000000000000000") {
            from_amount = e.target.value;
            from_amount = amountConvert(from_amount, fromTokenDecimal);
            if (from_amount >= 1) {
              if (
                fromTokenAddress != undefined &&
                toTokenAddress != undefined
              ) {

                Contract_Router.methods
                  .getAmountsOut(from_amount, [
                    fromTokenAddress,
                    toTokenAddress,
                  ])
                  .call()
                  .then((result) => {
                    var to_token_price_val_wei = result[1];
                    var to_token_price_val =
                      to_token_price_val_wei / Math.pow(10, toTokenDecimal);
                    to_amount = to_token_price_val.toFixed(6);
                    setToTokenValue(to_token_price_val.toFixed(6));
                  })
                  .catch((err) => {
                    // toggle8();
                    toast.error("Not Enough Liqudity to Swap")

                    setFromTokenValue("");
                    setToTokenValue("");
                  });
              }
            } else {
              setToTokenValue("");
            }
          }
        });
    }
  };

  const getValueIn = async (e) => {
    if (fromTokenAddress != undefined && toTokenAddress != undefined) {
      Contract_Factory.methods
        .getPair(fromTokenAddress, toTokenAddress)
        .call()
        .then((pair_address) => {
          if (pair_address == "0x0000000000000000000000000000000000000000") {
            // setAlert("Invalid Pair")
            // toggle55();
            setFromTokenValue();
            setToTokenValue();
          }


          if (pair_address != "0x0000000000000000000000000000000000000000") {
            to_amount = e.target.value;
            to_amount = amountConvert(to_amount, toTokenDecimal);
            if (to_amount >= 1) {
              if (
                fromTokenAddress != undefined &&
                toTokenAddress != undefined
              ) {
                Contract_Router.methods
                  .getAmountsIn(to_amount, [fromTokenAddress, toTokenAddress])
                  .call()
                  .then((result) => {

                    var from_token_price_val_wei = result[0];
                    var from_token_price_val =
                      from_token_price_val_wei / Math.pow(10, fromTokenDecimal);

                    from_amount = from_token_price_val.toFixed(6);
                    setFromTokenValue(from_token_price_val.toFixed(6));
                  })
                  .catch((err) => {
                    // toggle8();
                    toast.error("Not Enough Liqudity to Swap")
                    setFromTokenValue("");
                    setToTokenValue("");
                  });
              }
            } else {
              setFromTokenValue("");

            }
          }
        });
    }
  };

  const exchange = async () => {
    from_amount = fromTokenValue;
    to_amount = toTokenValue;

    from_amount = amountConvert(from_amount, fromTokenDecimal);
    to_amount = amountConvert(to_amount, toTokenDecimal);


    var time = Math.floor(Date.now() / 1000);
    var time1 = txnDeadline;
    var time1 = time1 > 0 ? time1 * 60000 : 1 * 60000;
    var dead_time = parseInt(time1);
    var deadline = (time + dead_time).toString();
  
    console.log( fromTokenValue,  fromBalance)
   
    if (fromTokenValue <= fromBalance) {
      if (address != "" && address != undefined) {
        // if (GWEI == 0) {
        //   web3.eth.getGasPrice((e, gas) => {
        //     gas_Price = gas;
        //   });
        // } else {
        //   gas_Price = GWEI + (GWEI * (tolerance / 100));
        // }
        // if (parseFloat(fromTokenValue) == parseFloat(fromBalance)) {
        //   // setMessage("Invalid Balance")
        //   // toggle55()
        //   toast.error("Invalid Balance")

        // }
        // else
         if (
          from_amount != "" &&
          from_amount != undefined &&
          to_amount != "" &&
          to_amount != undefined
        ) {
          if (fromTokenName == "ETH") {
            setLoad(true)
            setMessage("Exchanging the Tokens....")
            let exchange_fn = await new web3.eth.Contract(Router_ABI, Router_Address);
            let approve_fn = await new web3.eth.Contract(Approve_ABI, toTokenAddress)


            ////

            approve_fn.methods.allowance(address, Router_Address).call().then((res) => {

              if (res < 1157920892373161954235709850086879) {
                approve_fn.methods.approve(Router_Address, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
                  .send({ from: address })
                  .on("receipt", (res) => {
                    exchange_fn.methods
                      .swapExactETHForTokens(
                        0,
                        [Weth_Address, toTokenAddress],
                        address,
                        deadline
                      )
                      .send({ from: address, gasPrice: gas_Price, value: from_amount })
                      .on("receipt", (hash) => {
                        if (hash.status) {
                          setLoad(false)

                          setTimeout(function () {
                            setLoad(false)
                            transHistory(hash.blockHash, address, fromTokenName, toTokenName, from_amount, to_amount);
                            balanceChange()
                          }, 1000);
                          setFromTokenValue("")
                          setToTokenValue("")
                        }

                      })
                      .on("error", (res) => {
                        setLoad(false)
                        setToTokenValue("");
                        setFromTokenValue("")
                        // setMessage("Transaction Cancelled")
                        // toggle55()
                        toast.error("Transaction Cancelled")

                      });
                  })
                  .on("error", (res) => {
                    setLoad(false)
                    setToTokenValue("");
                    setFromTokenValue("")
                    // setMessage("Transaction Cancelled")
                    // toggle55()
                    toast.error("Transaction Cancelled")

                  });
              }
              else {
                exchange_fn.methods
                  .swapExactETHForTokens(
                    0,
                    [Weth_Address, toTokenAddress],
                    address,
                    deadline
                  )
                  .send({ from: address, gasPrice: gas_Price, value: from_amount })
                  .on("receipt", (hash) => {
                    if (hash.status) {
                      setLoad(false)

                      setTimeout(function () {
                        setLoad(false)
                        transHistory(hash.blockHash, address, fromTokenName, toTokenName, from_amount, to_amount);
                        balanceChange()
                      }, 1000);
                      setFromTokenValue("")
                      setToTokenValue("")
                    }

                  })
                  .on("error", (res) => {
                    setLoad(false)
                    setToTokenValue("");
                    setFromTokenValue("")
                    // setMessage("Transaction Cancelled")
                    // toggle55()
                    toast.error("Transaction Cancelled")

                  });
              }
            })

            ///



          }
          else if (toTokenName == "ETH") {
            setLoad(true)
            setMessage("Exchanging the Tokens....")
            let exchange_fn = await new web3.eth.Contract(Router_ABI, Router_Address);
            let approve_fn = await new web3.eth.Contract(Approve_ABI, fromTokenAddress)

            ///
            approve_fn.methods.allowance(address, Router_Address).call().then((res) => {
              if (res < 1157920892373161954235709850086879) {
                approve_fn.methods.approve(Router_Address, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
                  .send({ from: address })
                  .on("receipt", (resce) => {
                    exchange_fn.methods
                      .swapExactTokensForETH(
                        //from_amount,
                        0,
                        [Weth_Address, fromTokenAddress],
                        address,
                        deadline
                      )
                      .send({ from: address, gasPrice: gas_Price, value: from_amount })
                      .on("receipt", (hash) => {
                        setFromTokenValue("")
                        setToTokenValue("")
                        setTimeout(function () {
                          setLoad(false)
                          transHistory(hash.blockHash, address, fromTokenName, toTokenName, from_amount, to_amount);
                          balanceChange()
                        }, 1000);
                        toggle55()

                      })
                      .on("error", (res) => {
                        setLoad(false)
                        setToTokenValue("");
                        setFromTokenValue("")
                        // setMessage("Transaction Cancelled")
                        // toggle55()
                        toast.error("Transaction Cancelled")

                      });
                  })
                  .on("error", (res) => {
                    setLoad(false)
                    setToTokenValue("");
                    setFromTokenValue("")
                    // setMessage("Transaction Cancelled")
                    // toggle55()
                    toast.error("Transaction Cancelled")

                  });

              }
              else {
                exchange_fn.methods
                  .swapExactTokensForETH(
                    //from_amount,
                    0,
                    [Weth_Address, fromTokenAddress],
                    address,
                    deadline
                  )
                  .send({ from: address, gasPrice: gas_Price, value: from_amount })
                  .on("receipt", (hash) => {
                    setFromTokenValue("")
                    setToTokenValue("")
                    setTimeout(function () {
                      setLoad(false)
                      transHistory(hash.blockHash, address, fromTokenName, toTokenName, from_amount, to_amount);
                      balanceChange()
                    }, 1000);
                    toggle55()

                  })
                  .on("error", (res) => {
                    setLoad(false)
                    setToTokenValue("");
                    setFromTokenValue("")
                    // setMessage("Transaction Cancelled")
                    // toggle55()
                    toast.error("Transaction Cancelled")

                  });
              }
            })
            ///

          }
          else {
            setLoad(true)
            setMessage("Approving the tokens....")
            var approve_fn1 = await new web3.eth.Contract(Approve_ABI, fromTokenAddress);
            var approve_fn2 = await new web3.eth.Contract(Approve_ABI, toTokenAddress);
            var exchange_fn1 = await new web3.eth.Contract(Router_ABI, Router_Address);


            approve_fn1.methods.allowance(address, Router_Address).call().then((app1) => {
              approve_fn2.methods.allowance(address, Router_Address).call().then(async (app2) => {
                if (app1 < 1157920892373161954235709850086879 || app2 < 1157920892373161954235709850086879) {
                  await new Promise((reso, rej) => {
                    approve_fn1.methods
                      .approve(Router_Address, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
                      .send({ from: address })
                      .on("receipt", (receipt) => {
                        reso(true);

                      })
                      .on("error", (res) => {
                        setLoad(false)
                        setToTokenValue("");
                        setFromTokenValue("")
                        // setMessage("Transaction Cancelled")
                        // toggle55()
                        toast.error("Transaction Cancelled")
                      });
                  })

                  await new Promise((reso, rej) => {
                    approve_fn2.methods
                      .approve(Router_Address, "115792089237316195423570985008687907853269984665640564039457584007913129639935")
                      .send({ from: address })
                      // setMessage("Swapping the Tokens....")

                      .on("receipt", (receipt) => {
                        reso(true);

                      })
                      .on("error", (res) => {
                        setLoad(false)
                        setToTokenValue("");
                        setFromTokenValue("")
                        // setMessage("Transaction Cancelled")
                        // toggle55()
                        toast.error("Transaction Cancelled")


                      });

                  })

                  await new Promise((reso, rej) => {
                    exchange_fn1.methods
                      .swapExactTokensForTokens(
                        from_amount,
                        0,
                        [fromTokenAddress, toTokenAddress],
                        address,
                        deadline
                      )
                      .send({ from: address, gasPrice: gas_Price })
                      .on("receipt", (hash) => {
                        setTimeout(function () {
                          setLoad(false)
                          transHistory(hash.blockHash, address, fromTokenName, toTokenName, from_amount, to_amount);
                          balanceChange()
                        }, 10000);
                      })
                      .on("error", (res) => {
                        setLoad(false)
                        setToTokenValue("");
                        setFromTokenValue("")
                        // setMessage("Transaction Cancelled")
                        // toggle55()
                        toast.error("Transaction Cancelled")

                      });

                  })

                }
                else {
                  exchange_fn1.methods
                    .swapExactTokensForTokens(
                      from_amount,
                      0,
                      [fromTokenAddress, toTokenAddress],
                      address,
                      deadline
                    )
                    .send({ from: address, gasPrice: gas_Price })
                    .on("receipt", (hash) => {
                      setTimeout(function () {
                        setLoad(false)
                        transHistory(hash.blockHash, address, fromTokenName, toTokenName, from_amount, to_amount);
                        balanceChange()
                      }, 10000);
                    })
                    .on("error", (res) => {
                      setLoad(false)
                      setToTokenValue("");
                      setFromTokenValue("")
                      // setMessage("Transaction Cancelled")
                      // toggle55()
                      toast.error("Transaction Cancelled")

                    });

                }

              })
            })







            //   // approve_fn1.methods
            //   //   .approve(Router_Address, from_amount)
            //   //   .send({ from: address })
            //   //   .on("receipt", (receipt) => {


            //   //     approve_fn2.methods
            //   //       .approve(Router_Address, to_amount)
            //   //       .send({ from: address})
            //   //     setMessage("Swapping the Tokens....")

            //   //       .on("receipt", (receipt) => {

            //   //         exchange_fn1.methods
            //   //           .swapExactTokensForTokens(
            //   //             from_amount,
            //   //             0,
            //   //             [fromTokenAddress, toTokenAddress],
            //   //             address,
            //   //             deadline
            //   //           )
            //   //           .send({ from: address, gasPrice: gas_Price })
            //   //           .on("receipt", (hash) => {
            //   //             setFromTokenValue("")
            //   //             setToTokenValue("")
            //   //             setTimeout(function () {
            //   //               setLoad(false)
            //   //               transHistory(hash.blockHash, address, fromTokenName, toTokenName, from_amount, to_amount);
            //   //               balanceChange()
            //   //             }, 10000);
            //   //           })
            //   //           .on("error", (res) => {
            //   //             setLoad(false)
            //   //             setToTokenValue("");
            //   //             setFromTokenValue("")
            //   //             setAlert("Transaction Cancelled")
            //   //             toggle55()
            //   //           });


            //   //       })
            //   //       .on("error", (res) => {
            //   //         setLoad(false)
            //   //         setToTokenValue("");
            //   //         setFromTokenValue("")
            //   //         setAlert("Transaction Cancelled")
            //   //         toggle55()

            //   //       });

            //   //   })
            //   //   .on("error", (res) => {
            //   //     setLoad(false)
            //   //     setToTokenValue("");
            //   //     setFromTokenValue("")
            //   //     setAlert("Transaction Cancelled")
            //   //     toggle55()

            //   //   });
          }
        }
        else {
          setLoad(false)
          // setAlert("Connect MetaMask")
          // toggle55()
          toast.error("Connect Metamask")

        }
      }
      else {
        // setMessage("Connect Metamask")
        // toggle55()
        toast.error("Connect Metamask")
      }
    }
    else {
      toast.info("Insufficient Amount Please Check From & To Token Balance.");
    }

  };

  const transHistory = (
    hash,
    address,
    fromToken,
    toToken, 
    fromAmount,
    toAmount,

  ) => {
    fromAmount = fromAmount / Math.pow(10, fromTokenDecimal)
    toAmount = toAmount / Math.pow(10, toTokenDecimal)

    const request = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        hash: hash,
        userAddress: address,
        fromToken: fromToken,
        toToken: toToken,
        toAmount: toAmount,
        fromAmount: fromAmount,

      }),
    };
    fetch(
      "https://api.collarswap.io/api/swap/addswap", request
    )
      .then((data) => {
        setLoad(false)
        toast.success("Transaction Successfull")
        // setMessage("Transaction Successfull")
        // toggle55()
        setFromTokenValue("")
        setToTokenValue("")
        SetTrigger(!triggerCall)
      })


  };

  const selectFrom = (e, name, decimal, img) => {

    if (address != undefined && address != "undefined" && address) {
      setFromTokenDecimal(decimal)
      setFromTokenName(name)
      setFromTokenAddress(e)
      setFromTokenImg(img)

      let arr = []
      let a = [...tokenData]
      a.map((data) => {
        if (data.tokenName !== name) {
          arr.push(data)
        }

      })
      setToInitialTokenList(arr)

      if (name == "ETH") {
        web3.eth.getBalance(address).then((balc) => {
          let a = (balc / Math.pow(10, decimal));
          let b = a.toFixed(2)
          setFromBalance(b);

        });
      }
      else {
        var contract = new web3.eth.Contract(Approve_ABI, e);
        contract.methods
          .balanceOf(address)
          .call()
          .then((tokenbal) => {
            var tokenbal_ori = tokenbal / Math.pow(10, decimal);
            var newval = tokenbal_ori.toFixed(2);
            setFromBalance(newval);
          });
      }
    }
    else {
      setFromTokenDecimal(decimal)
      setFromTokenName(name)
      setFromTokenAddress(e)
      setFromTokenImg(img)
    }
  };
  const selectTo = (e, name, decimal, img) => {
    if (address != undefined && address != "undefined" && address) {

      setToTokenAddress(e)
      setToTokenDecimal(decimal)
      setToTokenName(name)
      setToTokenImg(img)

      let arr = []
      let a = [...tokenData]
      a.map((data) => {
        if (data.tokenName !== name) {
          arr.push(data)

        }
      })
      setInitialTokenList(arr)


      if (name == "ETH") {
        web3.eth.getBalance(address).then((balc) => {
          let a = (balc / Math.pow(10, decimal));
          let b = a.toFixed(2)
          setToBalance(a);
        });
      } else {
        var contract = new web3.eth.Contract(Approve_ABI, e);
        contract.methods
          .balanceOf(address)
          .call()
          .then((tokenbal) => {
            var tokenbal_ori = tokenbal / Math.pow(10, decimal);
            var newval = tokenbal_ori.toFixed(2);
            setToBalance(newval);
          });
      }
    }
    else {
      setToTokenAddress(e)
      setToTokenDecimal(decimal)
      setToTokenName(name)
      setToTokenImg(img)

    }
  };

  const refreshFrom = () => {
    setFromTokenValue("");
  };
  const refreshTo = () => {
    setToTokenValue("");
  };





  const swapButton = () => {
    setFromTokenName(toTokenName);
    setToTokenName(fromTokenName);
    setFromTokenAddress(toTokenAddress);
    setToTokenAddress(fromTokenAddress);
    setFromTokenValue("");
    setToTokenValue("");
    setFromBalance(toBalance);
    setToBalance(fromBalance);
    setToTokenImg(fromTokenImg)
    setFromTokenImg(toTokenImg)
    setFromTokenDecimal(toTokenDecimal)
    setToTokenDecimal(fromTokenDecimal)
  };
  const fromSearch = (e) => {
    let arr = []
    let a = [...tokenData]



    if (e.target.value !== "" && e.target.value !== undefined) {
      let token = e.target.value
      let test = token.toLowerCase()
      var searchResult = a.filter(word => word.tokenName.toLowerCase().indexOf(test) > -1);
      setInitialTokenList(searchResult)
    }
    else {
      setInitialTokenList(a)
    }



  }
  const toSearch = (e) => {
    let a = [...tokenData]
    if (e.target.value !== "" && e.target.value !== undefined) {
      var keywordToSearch = e.target.value;
      var keyword = keywordToSearch.toLowerCase();
      var searchResult = a.filter(word => word.tokenName.toLowerCase().indexOf(keyword) > -1);
      setToInitialTokenList(searchResult);
    } else {
      setToInitialTokenList(a);

    }
  }

  const LiquidityPerToken = () => {

    if (fromTokenAddress && toTokenAddress) {
      var Contract_Factory = new web3.eth.Contract(Factory_ABI, Factory_Address);

      Contract_Factory.methods
        .getPair(fromTokenAddress, toTokenAddress)
        .call()
        .then((pair_address) => {
          if (pair_address == "0x0000000000000000000000000000000000000000") {
            // setMessage("Invalid Pair")
            //toggle55();
            toast.error("Invalid Pair")
          }
        })
    }
  }
  useEffect(() => {
    if (fromTokenAddress !== undefined && toTokenAddress !== undefined) {

      LiquidityPerToken();
    }

  }, [fromTokenAddress, toTokenAddress])

  if (fromTokenName == toTokenName) {
    setToTokenName("choose")
    setToTokenImg()

    setFromTokenValue("")
    setToTokenValue("")
    setToBalance(0)
  }

 

  return (
    <Fragment>
      <LoadingOverlay active={load} spinner text={message} styles={{

        content: (base) => ({
          ...base,
          color: '#FEA501'
        }),
        spinner: (base) => ({
          ...base,
          margin: '300px auto 10px auto',
          width: '100px',
          '& svg circle': {
            stroke: '#FEA501'
          }
        }),
        overlay: (base) => ({
          ...base,
          background: 'rgb(0, 0, 0, 0.7)'
        })
      }}>
        <div className="cnt-load-div">
          <div className="container-fluid p-0">
            <div className="basic-layout">
              <div className="container">
                <div className="main-content-load-div">
                  <Sidebar openSidebar={sidebarStatus} />
                  <div className="main-content">
                    <Header toggleSidebar={setSidebarStatus} sidebarStatus={sidebarStatus} />
                    <div className="swap-page">
                      <div className="row mt-5 mb-4 mx-0">
                        <div className="col-lg-12">
                          <div className="swap-title-div p-3 row">
                            <img
                              src={newdoge2}
                              className="dog d-block img-fluid col-md-4"
                            />
                            <h3 className=" col-md-4">Swap</h3>
                            <img
                              src={newdoge1}
                              className="dog d-block img-fluid  col-md-4"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-lg-12">
                          <div className="swap-liq-div">
                            <div className="refresh-settings-div">
                              <img src={refreshImg} onClick={toggle4} />
                              <img
                                src={settingsImg}
                                className="ml-2"
                                onClick={toggle3}
                              />
                            </div>
                            <Nav tabs>
                              <NavItem>
                                {/* <NavLink

                                >
                                  <Link className="text-light text-decoration-none" to="/liquidity">Liquidity</Link>
                                </NavLink> */}
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  className="active"
                                >
                                  Swap
                                </NavLink>
                              </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                              <TabPane tabId="1">
                                <div className="row align-items-center">
                                  <div className="col-lg-5">
                                    <div className="swap-liq-det-div p-3">
                                      <div className="coin-exchange-div mb-3">
                                        <label className="mr-2 mt-1">From </label>
                                        <div
                                          className="d-flex align-items-center cur-point"
                                          onClick={toggle1}
                                        >
                                          <img src={fromTokenImg} />
                                          <span className="mx-2">
                                            {fromTokenName}
                                          </span>
                                          <img src={downarrowImg} />
                                        </div>
                                      </div>


                                      <label>Enter Your Amount <span onClick={() => { maxClick(fromTokenAddress, fromTokenName, "from") }} style={{ color: "#FF110B", width: "80px", cursor: "pointer" }}>(Max)</span></label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="0.00"
                                        value={fromTokenValue}
                                        onKeyUp={getValueOut}
                                        onChange={(e) => {
                                          const amount = e.target.value;
                                          var regexp = new RegExp(
                                            "^[0-9]*[.]?[0-9]*$"
                                          );
                                          if (regexp.test(amount)) {
                                            setFromTokenValue(amount);
                                          }
                                        }}
                                      />
                                      <div>
                                        <label className="mx-1">
                                          Balance : {fromBalance}
                                        </label>
                                      </div>

                                    </div>
                                  </div>
                                  <div className="col-lg-2" onClick={swapButton}>
                                    <div className="p-3">
                                      {/* {imageRotate() ?  <img 
                                        src={exchangeImg}
                                        style={{ transform: "rotate(90deg) " }} 
                                        className="d-block m-auto img-fluid "
                                      />:  */}
                                      <img
                                        src={exchangeImg}
                                        className="d-block m-auto img-fluid  mobile-arrow"
                                      />
                                      {/* } */}
                                    </div>
                                  </div>
                                  <div className="col-lg-5">
                                    <div className="swap-liq-det-div p-3">
                                      <div className="coin-exchange-div mb-3">
                                        <label className="mr-2 mt-1">To</label>
                                        <div
                                          className="d-flex align-items-center cur-point"
                                          onClick={toggle2}
                                        >
                                          <img src={toTokenImg} />
                                          <span className="mx-2">
                                            {toTokenName}
                                          </span>
                                          <img src={downarrowImg} />
                                        </div>
                                      </div>


                                      <label>Enter Your Amount <span onClick={() => { maxClick(toTokenAddress, toTokenName, "to") }} style={{ color: "#FF110B", width: "80px", cursor: "pointer" }}>(Max)</span></label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="0.00"
                                        value={toTokenValue}
                                        onKeyUp={getValueIn}
                                        onChange={(e) => {
                                          const amount = e.target.value;

                                          var regexp = new RegExp(
                                            "^[0-9]*[.]?[0-9]*$"
                                          );
                                          if (regexp.test(amount)) {
                                            setToTokenValue(amount);
                                          }
                                        }}
                                      />
                                      <div>
                                        <label className="mx-1">
                                          Balance : {toBalance}
                                        </label>
                                      </div>

                                    </div>
                                  </div>
                                  <div className="col-lg-12">

                                    {/* {fromTokenName == toTokenName ? (
                                      <button className="btn con-wal-btn" onClick={() => { setMessage("invalid Pair"); toggle55() }}>
                                        Invalid pair
                                      </button>
                                    ) : (
                                      fromTokenName !== toTokenName && toTokenAddress ?
                                        <button
                                          className="btn con-wal-btn"

                                          disabled={
                                            toTokenValue > 0 &&
                                              fromTokenValue > 0 &&
                                              fromTokenName !== toTokenName &&
                                              toTokenAddress
                                              ? false
                                              : true
                                          }
                                          onClick={exchange}
                                        >
                                          SWAP
                                        </button> :
                                        <button className="btn con-wal-btn" onClick={() => {
                                          setMessage("Please select Token")
                                          toggle55()
                                        }}>Swap</button>
                                    )} */}

                                    {address != "" && address != undefined ?
                                      <button
                                        className="btn con-wal-btn"

                                        disabled={
                                          toTokenValue > 0 &&
                                            fromTokenValue > 0 &&
                                            fromTokenName !== toTokenName &&
                                            toTokenAddress
                                            ? false
                                            : true
                                        }
                                        onClick={exchange}
                                      >
                                        SWAP
                                      </button> :
                                      <button className="btn con-wal-btn" onClick={() => {
                                        // setMessage("Please Connect Wallet")
                                        // toggle55()
                                        toast.error("Please Connect Wallet")
                                      }}>Connect Wallet</button>
                                    }
                                  </div>
                                </div>
                              </TabPane>
                            </TabContent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={modal1} toggle={toggle1} className="sel-coin-modal">
          <ModalHeader toggle={toggle1}>Select Token</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search name or Paste address"
                  onKeyUp={fromSearch}
                />

                {initialTokenList.map((data, i) => {
                  return (
                    <div
                      className="coin-list mb-2"
                      onClick={() => {
                        selectFrom(
                          data.tokenAddress,
                          data.tokenName,
                          data.tokenDecimal,
                          data.tokenImg
                        );
                        toggle1();
                        refreshFrom();
                        refreshTo();
                      }}
                    >
                      <div className="ldiv">
                        <img src={data.tokenImg} className="mr-2" />
                        <span>{data.tokenName}</span>
                      </div>
                    </div>
                  );
                })}

              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={modal2} toggle={toggle2} className="sel-coin-modal">
          <ModalHeader toggle={toggle2}>Select Token</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder="Search name or Paste address"
                  onKeyUp={toSearch} />

                {toInitialTokenList.map((data, i) => {
                  return (
                    <div
                      className="coin-list mb-2"
                      onClick={() => {

                        selectTo(
                          data.tokenAddress,
                          data.tokenName,
                          data.tokenDecimal,
                          data.tokenImg
                        );
                        toggle2();
                        refreshFrom();
                        refreshTo();
                      }}
                    >
                      <div className="ldiv">
                        <img src={data.tokenImg} className="mr-2" />
                        <span>{data.tokenName}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={modal3} toggle={toggle3} className="settings-modal">
          <ModalHeader toggle={toggle3}>Settings</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12">
                <h3>Default Transaction Speed (GWEI)</h3>
                <div className="speed-div">
                  {GWEI == 5000000000 ? <div
                    className="value"
                    style={{ background: "linear-gradient(0deg, #FEA501 -0.01%, #692705 100.03%)" }}

                    onMouseUp={() => {
                      setGWEI(5 * 1000000000);
                    }}
                  >
                    <span >Standard (5)</span>
                  </div> : <div
                    className="value"

                    onMouseUp={() => {
                      setGWEI(5 * 1000000000);
                    }}
                  >
                    <span >Standard (5)</span>
                  </div>}
                  {GWEI == 6000000000 ? <div
                    className="value"
                    style={{ background: "linear-gradient(0deg, #FEA501 -0.01%, #692705 100.03%)" }}

                    onMouseUp={() => {
                      setGWEI(6 * 1000000000);
                    }}
                  >
                    <span>Fast (6)</span>
                  </div> : <div
                    className="value"
                    onMouseUp={() => {
                      setGWEI(6 * 1000000000);
                    }}
                  >
                    <span>Fast (6)</span>
                  </div>}
                  {GWEI == 7000000000 ? <div
                    className="value"
                    style={{ background: "linear-gradient(0deg, #FEA501 -0.01%, #692705 100.03%)" }}

                    onMouseUp={() => {
                      setGWEI(7 * 1000000000);
                    }}
                  >
                    <span>Instant (7)</span>
                  </div> : <div
                    className="value"
                    onMouseUp={() => {
                      setGWEI(7 * 1000000000);
                    }}
                  >
                    <span>Instant (7)</span>
                  </div>}
                </div>
                <div className="mode-div">

                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h3 className="mb-0">SLIPPAGE TOLERANCE</h3>
                    <input
                      type="text"
                      maxlength="3"

                      class="form-control"
                      placeholder="Max 3"
                      onChange={(e) => {
                        setTolerance(e.target.value);
                      }}
                      value={tolerance}
                    />
                  </div>
                </div>
                {/* <div className="slippage-div">
                  <div
                    className="value"
                    onClick={() => {
                      setTolerance(0.1);
                      toggle2();
                    }}
                  >
                    <span>0.1%</span>
                  </div>
                  <div
                    className="value"
                    onClick={() => {
                      setTolerance(0.5);
                      toggle2();
                    }}
                  >
                    <span>0.5%</span>
                  </div>
                  <div
                    className="value"
                    onClick={() => {
                      setTolerance(1);
                      toggle2();
                    }}
                  >
                    <span>1.0%</span>
                  </div>
                  <div
                    className="value"
                    onClick={() => {
                      setTolerance(2);
                      toggle2();
                    }}
                  >
                    <span>2.0%</span>
                  </div>
                </div> */}
                <div className="mode-div">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <span>Tx deadline (mins)</span>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="20"
                      onChange={(e) => {
                        setTxnDeadline(e.target.value);
                      }}
                      maxlength="3"
                      value={txnDeadline}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={modal8} toggle={toggle8} className="settings-modal">
          <ModalBody>
            <div className="row">
              <div className="col-lg-12">
                <div className="recent-trans-table">
                  <div className="table-responsive">
                    <h1 className="d-flex justify-content-center text-light ">
                      Not Enough Tokens in Liquidity to Swap
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>


        <Modal isOpen={modal4} toggle={toggle4} className="settings-modal">
          <ModalHeader toggle={toggle4}>Recent Transaction</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12">
                <div className="recent-trans-table">
                  <div className="table-responsive">
                    {swapHistory.map((data) => {
                      return (
                        <table className="table">
                          <tbody>
                            <tr>

                              <td>SWAP  </td>
                              <td>{data.fromToken} {data.fromAmount} </td>
                              <td>For</td>
                              <td> {data.toToken} {data.toAmount}</td>
                            </tr>

                          </tbody>
                        </table>
                      )
                    })}

                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* <Modal isOpen={modal55} toggle={toggle55} className="settings-modal"> */}
        <Modal isOpen={modal55} toggle={toggle55} className="comingsoon-poup">


          <ModalBody>
            <div class="text-center">
              <h5>{message}</h5>
            </div>
            {/* <div className="row">
              <div className="col-lg-12">
                <div className="recent-trans-table">
                  <div className="table-responsive">
                    <p className="d-flex justify-content-center text-light ">
                      {alert}
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </ModalBody>
        </Modal>
      </LoadingOverlay>
    </Fragment>
  );
};

export default Swap;
