import React, { Component, Fragment, useState, useRef, useEffect, createRef, useHistory } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Input, Dropdown, UncontrolledDropdown, DropdownToggle, Modal, ModalBody, DropdownMenu, DropdownItem, UncontrolledCollapse, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import doge from "../../assets/images/dog.png"
import "./css/home.scss";
import Header from "../../common/Header/Header";
import Sidebar from "../../common/Sidebar/Sidebar";

import titlebgImg from '../../assets/images/titlebg.png';
import dclogobigImg from '../../assets/images/dclogobig.png';
import dogImg from '../../assets/images/dog.png';
import logomobImg from '../../assets/images/logomob.png';
import logoImg from '../../assets/images/logo.png';
import greendotsImg from '../../assets/images/greendots.png';
import parentdog1 from '../../assets/images/parentdog1.png';
import newdoge from '../../assets/images/newdoge.png';



import Countdown from 'react-countdown';

import { ToastContainer, toast } from 'react-toastify';

import LoadingOverlay from "react-loading-overlay";
import axios from "axios";

import Approve_ABI from "../../ABI/Approve_ABI.json"
import Stake_ABI from "../../ABI/Stake_ABI.json"
import Factory_ABI from "../../ABI/Factory_ABI.json"
import { Collar_Address, Gem_Address, Tag_Address, Stake_Address, Factory_Address } from "../../common/Contract_cons";



const Web3 = require("web3");
const web3 = new Web3(window.ethereum);

const Home = (props) => {


  const [modal10, setModal10] = useState(false);
  const toggle10 = () => setModal10(!modal10);

  const [load, setLoad] = useState(true)
  const [sidebarStatus, setSidebarStatus] = useState(false)
  const [data, setData] = useState([])

  const [totalSupply, setTotalSupply] = useState(0)
  const [totalStaked, setTotalStaked] = useState(0)
  const [collarBalance, setCollarBalance] = useState(0)
  const [blockNum, setBlockNum] = useState(0)

  const [availablePools, setAvailablePools] = useState(0)
  const [allPairs, setAllPairs] = useState(0) 

  const [message, setMessage] = useState("Error")
  const [test, setTest] = useState(1)



  var address = JSON.parse(window.localStorage.getItem("metaAddress"));
  useEffect(() => {
    var address = JSON.parse(window.localStorage.getItem("metaAddress"));

    if (address) {

      let contract = new web3.eth.Contract(Approve_ABI, Collar_Address)
      contract.methods.balanceOf(address).call().then((data) => {
        setCollarBalance(data / 1000000000000000000)
      })
    }



    axios.get("https://api.collarswap.io/api/sitesettings/getsitesettings")
      .then((res) => {
        setData(res.data.result)
      })
    setTimeout(() => {
      setLoad(false)
    }, 100)
  }, [])

  // useEffect(() => {
  //   toggle10()
  // }, [])
  let cc = localStorage.getItem("CCbalance")



  useEffect(() => {
    let contract = new web3.eth.Contract(Approve_ABI, Collar_Address)
    let contract2 = new web3.eth.Contract(Stake_ABI, Stake_Address)
    let contract3 = new web3.eth.Contract(Factory_ABI, Factory_Address)

    contract.methods.totalSupply().call().then((supp) => {
      setTotalSupply(supp / 1000000000000000000)
    })
    contract2.methods.currentPool().call().then((po) => {
      contract2.methods.veiwPools(po).call().then((data) => {
        setAvailablePools(po)

        setTotalStaked(data.totalStakedToken / 1000000000000000000)

      })
    })

    web3.eth.getBlockNumber()
      .then((blok) => {
        setBlockNum(blok)
      })
    contract3.methods.allPairsLength().call()
      .then((bal) => {
        setAllPairs(bal)
      })




    //   Marketcap -- Market cap sitela token price list pannatha bro ithu set aagum.

    // Total Minted -- Total supply of the token bro.

    // Total Burning -- Total burning amount of token.

    // Circulating supply -- (Total Mint token - Total Burn token).

    // Max Tx Amount -- Maximum amount per transaction

  }, [])



  var market = {
    marketCap: 22,
    totalBurned: 0,
    totalCirculation: totalSupply,
    maxTxAmount: 0,



  }

  const connectmetamask = async () => {
    // toggle()

    if (typeof window.ethereum !== "undefined") {


      try {
        const netId = await window.ethereum.request({ method: "eth_chainId" });
        if (parseInt(netId, 16) == 1) {
          await window.ethereum.enable();
          await window.ethereum.request({ method: "eth_requestAccounts" })
          const accounts = await web3.eth.getAccounts();
          var balance = await web3.eth.getBalance(accounts[0]);
          balance = await (balance / Math.pow(10, 18)).toFixed(4);
          var contract1 = new web3.eth.Contract(Approve_ABI, Gem_Address)
          contract1.methods.balanceOf(accounts[0]).call().then((bal) => {
            var test = bal / Math.pow(10, 18)
            test = test.toFixed(6)
            localStorage.setItem("Ebalance", JSON.stringify(test));

          })

          let acc = accounts[0].toLowerCase()
          localStorage.setItem("metaAddress", JSON.stringify(acc));

          var contract = new web3.eth.Contract(Approve_ABI, Collar_Address);
          contract.methods
            .balanceOf(accounts[0])
            .call()
            .then((tokenbal) => {
              var tokenbal_ori = tokenbal / Math.pow(10, 18);
              var newval = tokenbal_ori.toFixed(6);
              localStorage.setItem("CCbalance", JSON.stringify(newval));
            })

          setTest(test + 1)

          setTimeout(() => {

            window.location.reload()
            toast.success("Metamask Connected Successfully")

          }, 1000);

        } else {
          // setMessage("select Ethereum Mainnet Network")
          // toggle10()
          toast.error("select Ethereum Mainnet Network")
          setTimeout(() => {
            window.location.reload()

          }, 1000);
          return false;
        }
      } catch (e) {
        toast.error("No Metamask Detected")
        // setMessage("No metamsk Detected")
        // toggle10()

      }
    }
  };



  const Completionist = () => <span>Liquidity is Available Now!</span>;

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return <div className="time-box">
        <div className="inner-time">
          {days}
          <p>
            Days
          </p>
        </div>
        <div className="time-dot d-lg-block d-none">
          :
        </div>
        <div className="inner-time">
          {hours}
          
          <p>
            Hours
          </p>
        </div>
        <div className="time-dot d-lg-block d-none">
          :
        </div>

        <div className="inner-time">
          
          {minutes}
          <p>
            Minutes
          </p>
        </div>
        <div className="time-dot d-lg-block d-none ">
          :
        </div>
        <div className="inner-time">
          
          {seconds}
          <p>
            Seconds
          </p>
        </div>
      </div>;
    }
  };


  return (
    <Fragment>
      <LoadingOverlay
        active={load}
        spinner
        text="Loading please wait"
        styles={{
          content: (base) => ({
            ...base,
            color: "#FEA501",
          }),
          spinner: (base) => ({
            ...base,
            margin: "auto auto auto auto",
            width: "100px",
            "& svg circle": {
              stroke: "#FEA501",
            },
          }),
          overlay: (base) => ({
            ...base,
            background: "rgb(0, 0, 0, 0.7)",
          }),
        }}
      >
        <div className="cnt-load-div">
          <div className="container-fluid p-0">
            <div className="basic-layout">
              <div className="container">
                <div className="main-content-load-div">
                  <Sidebar openSidebar={sidebarStatus} />
                  <div className="main-content">
                    <Header toggleSidebar={setSidebarStatus} sidebarStatus={sidebarStatus} />
                    <div className="home-page">
                      <div className="row mt-5 mb-4 mx-0">
                        <div className="col-lg-12">
                          <div className="title-div p-3 row align-items-center">
                            <div className="col-lg-3"><img src={dclogobigImg} className="img-fluid mx-auto d-block" /></div>
                            <div className="col-lg-6">
                              <div className="title-cnt">
                                <h3><span className="red">COLLAR</span><span className="yellow">Swap.io</span></h3>
                                <p className="mb-0">Swap, earn, and win crypto on the
                                  most popular decentralized platform in the galaxy</p>
                              </div>
                            </div>
                            <div className="col-lg-3"><img src={newdoge} className="img-fluid dog mx-auto d-block" /></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 pr-lg-1">
                          <div className="farm-stake-div px-3 py-5">
                            <h4 className="mb-4">Farms & Staking</h4>
                            <div className="harvest-wallet-div mb-4">
                              <h5>Available Pools</h5>
                              <div className="rdiv">
                                <h6>{availablePools}</h6>
                                {/* <span>~$0.00</span> */}
                              </div>
                            </div>
                            <div className="harvest-wallet-div mb-4">
                              <h5>COLLAR in Wallet</h5>
                              <div className="rdiv">
                                <h6>    {collarBalance.toFixed(2)}</h6>
                                {/* <span>~$0.00</span> */}
                              </div>
                            </div>{address ? <button className="btn con-wal-btn"  >Connected</button>
                              : <button className="btn con-wal-btn"  onClick={connectmetamask}   >Connect Wallet</button>
                            }
                          </div>
                        </div>
                        <div className="col-lg-6 pl-lg-1">
                          <div className="farm-stake-div px-3 py-5">
                            <h4 className="mb-4">LIQUIDITY</h4>
                            <div className="harvest-wallet-div mb-4">
                              <h5>Available Pairs</h5>
                              <div className="rdiv">
                                <h6>{allPairs}</h6>
                                {/* <span>~$0.00</span> */}
                              </div>
                            </div>
                            <div className="harvest-wallet-div mb-4">
                              <h5>COLLAR in Wallet</h5>
                              <div className="rdiv">
                                <h6>{collarBalance.toFixed(2)}</h6>
                                {/* <span>~$0.00</span> */}
                              </div>
                            </div>
                            {address ? <button className="btn con-wal-btn"  >Connected</button>
                              : <button className="btn con-wal-btn" onClick={connectmetamask} >Connect Wallet</button>
                            }
                          </div>
                        </div>
                      </div>
                      {/* <div className="row mb-4">
                        <div className="col-lg-6 pr-lg-1 mb-4">
                          <div className="staked-val-stats-div p-5">
                            <h4>Total Value Locked (TVL)</h4>
                            <h5>{totalStaked}</h5>
                            <p>Across all current Stake</p>
                          </div>
                        </div>
                        <div className="col-lg-6 pl-lg-1">
                          <div className="staked-val-stats-div p-5">
                            <h4>Stats</h4>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <span>Total Supply</span>
                              <span className="val">{totalSupply} Tokens</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              <span>24H Volume</span>
                              <span className="val">$0</span>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="row mb-4">
                        <div className="col-lg-6 pr-lg-1">
                          <div className="announce-stats-div p-5">
                            <h4>Announcements</h4>
                            <div className="collar-bsc-div">
                              <img src={logomobImg} />
                              <div className="rdiv ml-3">
                                <p className="mb-0">COLLARSWAP</p>
                                {/* <p className="mb-0">COLLARSWAP #BSC</p> */}
                                {/* <span>@COLLARSWAP.Finance</span> */}
                              </div>
                            </div>
                            <div className="det-div-ann mb-4">
                              <p>Details about COLLARSWAP</p>
                              <a href={data.twitterURL}>Click to Twitter</a>
                            </div>
                            <div className="soon-div p-5">
                              <img src={logoImg} className="d-block mx-auto img-fluid" />
                              <p>Trade, earn, and win crypto on the most popular decentralized platform in the galaxy.</p>
                              <h6>COLLAR SWAP on live <img src={greendotsImg} className="ml-2" /></h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 pl-lg-1">
                          <div className="announce-stats-div p-5">
                            <h4>COLLARSWAPStats</h4>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <span>Market Cap</span>
                              <span className="val">{market.marketCap}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <span>Total Minted</span>
                              <span className="val">{market.totalCirculation}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <span>Total Burned</span>
                              <span className="val">{market.totalBurned}</span>
                            </div>
                            {/* <div className="d-flex justify-content-between align-items-center mb-3">
                              <span>Total Locked Rewards</span>
                              <span className="val">48,742,414</span>
                            </div> */}
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <span>Circulating Supply</span>
                              <span className="val">{totalSupply}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <span>Max Tx Amount</span>
                              <span className="val">{market.maxTxAmount}</span>
                            </div>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <span>New COLLAR SWAP/Block</span>
                              <span className="val">{blockNum}</span>
                            </div>
                            {/* <div className="d-flex justify-content-between align-items-center mb-3">
                              <span>Transfer Tax</span>
                              <span className="val">2.0%</span>
                            </div> */}
                            {/* <h4 className="my-4">COLLARSWAP Stats</h4>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <span>New COLLAR SWAP/Block</span>
                              <span className="val">$ 5.2658</span>
                            </div> */}
                            {/* <div className="d-flex justify-content-between align-items-center mb-3">
                              <span>Collarswap-BUSD</span>
                              <span className="val">$ 1.258</span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={modal10} toggle={toggle10} className="comingsoon-poup">
          {/* <ModalHeader toggle={toggle10}>Alert</ModalHeader> */}
          <ModalBody>
            <div class="text-center p-3">
              {/* <h3>KOVEN TESTNET EARLY ACCESS</h3> */}
              <h5 className="mb-5" style={{ fontSize: "30px", fontFamily: "italic" }}> CollarSwap.io Liquidity Event Grand OPENING</h5>
{/* <h3 style={{ fontSize: "30px", fontFamily: "italic" }}>Pending Return of Audit</h3> */}
              <h3 style={{ fontSize: "30px" }}> <Countdown
                date={Date.now() +  ((1667370950000 - Date.now()))}
                renderer={renderer}
              /></h3>
            </div>
          </ModalBody>
        </Modal>
      </LoadingOverlay>
    </Fragment>
  );

}

export default Home;